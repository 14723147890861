import React from "react";
import { InfoView } from "./info-views";
import { path } from "./path";
import { generatePath } from "react-router-dom";
import { useConsultation } from "./consultation-detail-view";
import { template } from "./utils";
import text from "./consultation-requested-view.json";

export const ConsultationRequestedView = () => {
  const consultation = useConsultation();

  return (
    <InfoView
      icon="rocket"
      heading={text.heading}
      text={template(text.text, { CONSULTATION_NAME: consultation.name })}
      links={[
        { label: text.back_to_all_topics, href: path.topicsRoot },
        {
          label: text.view_consultation,
          href: generatePath(path.consultationDetail, {
            consultationId: consultation.id,
          }),
        },
      ]}
    />
  );
};
