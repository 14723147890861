import React, { createContext, useContext } from "react";
import { Redirect, Switch, Route, useLocation } from "react-router-dom";
import { useStore } from "./store-manager";
import { Layout } from "./layout";
import { User } from "./types";
import { path } from "./path";
import { NotInPrototypeView } from "./info-views";
import { TopicsListingView } from "./topics-listing-view";
import { TopicDetailView } from "./topic-detail-view";
import { ConsultationsListingView } from "./consultations-listing-view";
import { ConsultationDetailView } from "./consultation-detail-view";
import { OpportunitiesListingView } from "./opportunities-listing-view";
import { OpportunityDetailView } from "./opportunity-detail-view";
import { MessageComposerView } from "./message-composer-view";
import { ErrorBoundary } from "./error-boundary";

const UserContext = createContext<User | undefined>(undefined);

export const useCurrentUser = () => {
  const user = useContext(UserContext);

  if (!user) {
    throw new Error("Couldn't retrieve User from context.");
  }

  return user;
};

export const UserView = () => {
  const store = useStore();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const userId = params.get("userId") || localStorage.getItem("userId");

  if (!userId) {
    return <Redirect to={path.login} />;
  }

  const user = store.getUser(userId);
  const redirectLocation =
    user?.type === "CONSULTANT" ? path.opportunitiesRoot : path.topicsRoot;

  return (
    <UserContext.Provider value={user}>
      <Switch>
        <Route path={path.messageComposer}>
          <ErrorBoundary>
            <MessageComposerView />
          </ErrorBoundary>
        </Route>
        <Route>
          <Layout>
            <Switch>
              {/* Topics */}
              <Route path={path.topicsRoot} exact>
                <TopicsListingView />
              </Route>
              <Route path={path.topicDetail} exact>
                <TopicDetailView />
              </Route>

              {/* Opportunities */}
              <Route path={path.opportunitiesRoot} exact>
                <OpportunitiesListingView />
              </Route>
              <Route path={path.opportunityDetail} exact>
                <ErrorBoundary>
                  <OpportunityDetailView />
                </ErrorBoundary>
              </Route>

              {/* Consultations */}
              <Route path={path.consultationsRoot} exact>
                <ConsultationsListingView />
              </Route>
              <Route path={path.consultationDetail}>
                <ErrorBoundary>
                  <ConsultationDetailView />
                </ErrorBoundary>
              </Route>

              {/* Other */}
              <Route path={path.notifs}>
                <NotInPrototypeView />
              </Route>
              <Route path={path.help}>
                <NotInPrototypeView />
              </Route>

              {/* Fallback */}
              <Redirect to={redirectLocation} />
            </Switch>
          </Layout>
        </Route>
      </Switch>
    </UserContext.Provider>
  );
};
