import React from "react";
import { color } from "./stylesheet";
import { pick } from "./utils";

type TextListProps = {
  children: string[];
  fontSize?: "tiny" | "small" | "medium";
};

export const TextList = (props: TextListProps) => {
  const size = props.fontSize || "medium";

  const fontSize = pick(size, {
    tiny: "0.875rem",
    small: "1rem",
    medium: "1.125rem",
  });

  const lineHeight = pick(size, {
    tiny: "1.125rem",
    small: "1.25rem",
    medium: "1.5rem",
  });

  return (
    <ul style={{ marginTop: "1rem", paddingLeft: "1.125rem" }}>
      {props.children.map((line, i) => {
        return (
          <li
            key={i}
            style={{
              listStyle: "disc",
              fontSize: fontSize,
              color: color.DARK_GRAY,
              lineHeight: lineHeight,
            }}>
            {line}
          </li>
        );
      })}
    </ul>
  );
};
