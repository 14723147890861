import React, { ReactNode, useState } from "react";
import { color } from "./stylesheet";
import * as types from "./types";
import { MediumHeading, SmallHeading } from "./headings";
import { HtmlText } from "./html-text";
import { when, pick, template } from "./utils";
import { useStore } from "./store-manager";
import { Input } from "./input";
import { Button } from "./buttons";
import { IconCircle } from "./icon-circle";
import { useHover } from "./hooks";
import { getRating, toNow } from "./formatters";
import { Portrait } from "./portrait";
import { useCurrentUser } from "./user-view";
import text from "./timeline-view.json";

type TimelineBannerProps = {
  id?: string;
  icon?: string;
  portrait?: string;
  heading: string;
  text: string;
  children?: ReactNode;
  subtle?: boolean;
};

export const TimelineBanner = (props: TimelineBannerProps) => {
  return (
    <div
      id={props.id}
      style={{
        boxShadow: when(
          !props.subtle,
          `0 1px 0 0 ${color.LIGHT_GRAY}, 0 -1px 0 0 ${color.LIGHT_GRAY}`,
        ),
        backgroundColor: "white",
        padding: "4rem 0",
        display: "grid",
        gridTemplateColumns: "repeat(7, 1fr)",
        gridRowGap: "2rem",
      }}>
      <div
        style={{
          gridColumn: "2 / span 5",
          textAlign: "center",
          opacity: when(props.subtle, 0.5),
        }}>
        {(props.icon || props.portrait) && (
          <div
            style={{
              width: "4rem",
              height: "4rem",
              borderRadius: "50%",
              boxShadow: `0 0 0 1px ${color.LIGHT_GRAY}`,
              backgroundColor: "white",
              margin: "-5rem auto 1rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              overflow: "hidden",
            }}>
            <img
              src={props.icon ? `/icons/${props.icon}.svg` : props.portrait}
              alt=""
            />
          </div>
        )}
        <MediumHeading marginBottom="1rem">{props.heading}</MediumHeading>
        <HtmlText>{props.text}</HtmlText>
      </div>
      {props.children && (
        <div style={{ gridColumn: "span 7" }}>{props.children}</div>
      )}
    </div>
  );
};

const RequestedBanner = (props: types.ConsultationRequestedItem) => {
  const currentUser = useCurrentUser();
  const store = useStore();
  const topic = store.getConsultation(props.consultationId);

  if (!topic) {
    return null;
  }

  return (
    <div
      id={props.id}
      style={{
        paddingBottom: "2rem",
        backgroundColor: "white",
        boxShadow: `0 1px 0 0 ${color.LIGHT_GRAY}, 0 -1px 0 0 ${color.LIGHT_GRAY}`,
      }}>
      <div style={{ display: "flex", padding: "2rem 0", alignItems: "center" }}>
        <IconCircle icon="rocket" size="4rem" backgroundColor="white" border />
        <div style={{ marginLeft: "1rem" }}>
          <SmallHeading>
            {currentUser.type === "CLIENT"
              ? "Consultation requested"
              : "Background info"}
          </SmallHeading>
          <div style={{ color: color.FADED_BLACK, opacity: 0.5 }}>
            {currentUser.type === "CLIENT"
              ? "You submitted the below info"
              : "The client submitted the below info"}
          </div>
        </div>
      </div>
      <HtmlText marginBottom={"1.125rem"}>
        {props.consultationInputs === ""
          ? "<p>No consultation info provided</p>"
          : props.consultationInputs}
      </HtmlText>
    </div>
  );
};

const MatchedBanner = (props: types.ConsultantMatchItem) => {
  const store = useStore();
  const consultant = store.getConsultantOrThrow(props.consultantId);
  const consultation = store.getConsultationOrThrow(props.consultationId);

  return (
    <TimelineBanner
      id={props.id}
      portrait={consultant.portrait}
      heading={"Meet {CONSULTANT_NAME}".replace(
        "{CONSULTANT_NAME}",
        consultant.name,
      )}
      text={"{CONSULTANT_NAME} will consult for you regarding {TOPIC_NAME}."
        .replace("{CONSULTANT_NAME}", consultant.name)
        .replace("{TOPIC_NAME}", consultation.name)}>
      <div
        style={{
          display: "grid",
          gridTemplate: "auto / repeat(7, 1fr)",
        }}>
        <div
          style={{
            gridArea: "2 / span 2",
          }}>
          {consultant.highlights.map((highlight, i, arr) => {
            const isLast = i + 1 === arr.length;

            return (
              <div
                key={highlight.icon}
                style={{
                  display: "flex",
                  marginBottom: when(!isLast, "1rem"),
                }}>
                <img
                  src={`/icons/${highlight.icon}.svg`}
                  alt=""
                  style={{ marginRight: "1rem", flex: "0 0 auto" }}
                />
                <div style={{ color: color.DARK_GRAY }}>{highlight.text}</div>
              </div>
            );
          })}
        </div>
        <div style={{ gridArea: "2 / span 5", paddingLeft: "3.5rem" }}>
          <HtmlText>{consultant.bio}</HtmlText>
        </div>
      </div>
    </TimelineBanner>
  );
};

const MessageEntry = (props: types.EmailMessageItem) => {
  return (
    <div
      id={props.id}
      style={{
        boxShadow: `0 1px 0 0 ${color.LIGHT_GRAY}, 0 -1px 0 0 ${color.LIGHT_GRAY}`,
        backgroundColor: "white",
        paddingBottom: "4rem",
      }}>
      <div
        style={{
          padding: "1.5rem 0",
          display: "flex",
          alignItems: "center",
        }}>
        <Portrait userId={props.senderId} marginRight="1rem" />
        <div style={{ flexGrow: 1 }}>
          <div
            style={{
              fontSize: "1.125rem",
              lineHeight: "1.125rem",
              color: color.FADED_BLACK,
              fontWeight: 700,
            }}>
            {props.subject}
          </div>
          <div
            style={{
              marginTop: "0.125rem",
              fontSize: "1rem",
              lineHeight: "1rem",
              color: color.DARK_GRAY,
              opacity: 0.5,
            }}>
            {toNow(props.createdDate)}
          </div>
        </div>
      </div>
      <HtmlText>{props.message}</HtmlText>
      {props.attachemnts && props.attachemnts?.length !== 0 && (
        <div
          style={{
            marginTop: "2rem",
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gridGap: "1rem",
          }}>
          {props.attachemnts?.map(attachment => {
            return (
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "auto 1fr",
                  gridColumnGap: "1rem",
                  backgroundColor: color.VERY_LIGHT_GRAY,
                  borderRadius: "0.25rem",
                  padding: "0.75rem",
                  alignItems: "center",
                }}>
                <IconCircle icon="paperclip" backgroundColor="white" />
                <div>
                  <div style={{ color: color.FADED_BLACK, fontWeight: 700 }}>
                    {attachment.name}
                  </div>
                  <div
                    style={{
                      color: color.DARK_GRAY,
                      opacity: 0.5,
                      fontSize: "0.875rem",
                    }}>
                    {attachment.size}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

type RatingExplainerProps = {
  onClick: () => void;
  active: boolean | null;
  initialPosition: "left" | "center" | "right";
  position: "left" | "center" | "right";
  heading: string;
  text: string;
  rating: "passive" | "promoter" | "detractor";
};

const RatingExplainer = (props: RatingExplainerProps) => {
  const { hovered, handlers } = useHover();

  const VERTICAL_OFFSET = "2rem";
  const HORIZONTAL_OFFSET = "2rem";
  const ROTATION = "5deg";

  const translate = pick(props.initialPosition, {
    left: pick(props.position, {
      left: `translate3d(${HORIZONTAL_OFFSET}, ${VERTICAL_OFFSET}, 0)`,
      center: `translate3d(100%, calc(${VERTICAL_OFFSET} / 2), 0)`,
      right: `translate3d(calc(200% - ${HORIZONTAL_OFFSET}), ${VERTICAL_OFFSET}, 0)`,
    }),
    center: pick(props.position, {
      left: `translate3d(calc(${HORIZONTAL_OFFSET} - 100%), ${VERTICAL_OFFSET}, 0)`,
      center: `translate3d(0, calc(${VERTICAL_OFFSET} / 2), 0)`,
      right: `translate3d(calc(100% - ${HORIZONTAL_OFFSET}), ${VERTICAL_OFFSET}, 0)`,
    }),
    right: pick(props.position, {
      left: `translate3d(calc(200% - ${HORIZONTAL_OFFSET}), ${VERTICAL_OFFSET}, 0)`,
      center: `translate3d(-100%, calc(${VERTICAL_OFFSET} / 2), 0)`,
      right: `translate3d(-${HORIZONTAL_OFFSET}, ${VERTICAL_OFFSET}, 0)`,
    }),
  });

  const rotation = pick(props.position, {
    left: `rotate(-${ROTATION})`,
    center: "rotate(0)",
    right: `rotate(${ROTATION})`,
  });

  return (
    <div
      {...handlers}
      onClick={props.onClick}
      style={{
        cursor: "pointer",
        padding: "1.125rem 1.125rem 3rem",
        boxShadow: `0 0 0 1px ${color.LIGHT_GRAY}`,
        backgroundColor:
          hovered || props.active ? "white" : color.VERY_LIGHT_GRAY,
        zIndex: when(props.active, 1),
        transform: `${translate} ${rotation} scale(${
          props.active ? "1" : "0.9"
        })`,
      }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "1rem",
        }}>
        <IconCircle
          icon={pick(props.rating, {
            detractor: "thumbs-down-white",
            passive: "thumbs-up-white",
            promoter: "diamond-white",
          })}
          backgroundColor={pick(props.rating, {
            detractor: color.FADED_BLACK,
            passive: color.BLUE,
            promoter: color.ORANGE,
          })}
        />
        <div
          style={{
            fontWeight: 700,
            marginTop: "1rem",
            fontSize: "1.125rem",
          }}>
          {props.heading}
        </div>
      </div>
      <HtmlText fontSize="small">{props.text}</HtmlText>
    </div>
  );
};

const RatingEntry = (props: types.RateConsultantItem) => {
  const store = useStore();
  const currentUser = useCurrentUser();
  const [state, setState] = useState<number | null>(null);
  const [review, setRreview] = useState<string>("");

  const ratingIsDetractor = state !== null && state < 7;
  const ratingIsPassive = state !== null && state >= 7 && state < 9;
  const ratingIsPromoter = state !== null && state >= 9;
  const isConsultant = currentUser.type === "CONSULTANT";

  const rating = getRating(props.rating);

  if (props.rating) {
    if (rating === "unrated") {
      return null;
    }

    return (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(7, 1fr)",
          padding: "2rem 0",
          boxShadow: `0 1px 0 0 ${color.LIGHT_GRAY}, 0 -1px 0 0 ${color.LIGHT_GRAY}`,
        }}>
        <div
          style={{
            gridColumn: "span 2",
          }}>
          <div
            style={{
              marginTop: "1.5rem",
              transform: "scale(2)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <IconCircle
              icon={pick(rating, {
                promoter: "diamond-white",
                passive: "thumbs-up-white",
                detractor: "thumbs-down-white",
              })}
              backgroundColor={pick(rating, {
                passive: color.BLUE,
                promoter: color.ORANGE,
                detractor: color.FADED_BLACK,
              })}
            />
          </div>
        </div>
        <div style={{ gridColumn: "span 5" }}>
          <SmallHeading marginBottom="2rem">
            {pick(rating, {
              detractor: "Rated: " + text.ratingDetractorHeading,
              passive: "Rated: " + text.ratingPassiveHeading,
              promoter: "Rated: " + text.ratingPromoterHeading,
            })}
            {` (${props.rating} / 10)`}
          </SmallHeading>
          <HtmlText marginBottom="2rem">
            {isConsultant
              ? pick(rating, {
                  detractor: text.ratingClientDetractorText,
                  passive: text.ratingClientPassiveText,
                  promoter: text.ratingClientPromoterText,
                })
              : pick(rating, {
                  detractor: text.ratingConsultantDetractorText,
                  passive: text.ratingConsultantPassiveText,
                  promoter: text.ratingConsultantPromoterText,
                })}
          </HtmlText>
          <SmallHeading marginBottom="2rem">
            {ratingIsDetractor ? "Your Complaint" : "Your Review"}
          </SmallHeading>
          <HtmlText>{props.review || "<p>No review</p>"}</HtmlText>
        </div>
      </div>
    );
  }

  return (
    <TimelineBanner
      id={props.id}
      heading={text.ratingHeading}
      icon="briefcase"
      text={text.ratingText}>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          position: "relative",
        }}>
        <RatingExplainer
          onClick={() => setState(6)}
          active={ratingIsDetractor}
          initialPosition="left"
          position={ratingIsDetractor ? "center" : "left"}
          rating="detractor"
          heading={text.ratingDetractorHeading}
          text={
            isConsultant
              ? text.ratingClientDetractorText
              : text.ratingConsultantDetractorText
          }
        />
        <RatingExplainer
          onClick={() => setState(8)}
          active={ratingIsPassive}
          initialPosition="center"
          position={
            ratingIsPromoter ? "right" : ratingIsDetractor ? "left" : "center"
          }
          rating="passive"
          heading={text.ratingPassiveHeading}
          text={
            isConsultant
              ? text.ratingClientPassiveText
              : text.ratingConsultantPassiveText
          }
        />
        <RatingExplainer
          onClick={() => setState(10)}
          active={!!state && state >= 9}
          initialPosition="right"
          position={ratingIsPromoter ? "center" : "right"}
          rating="promoter"
          heading={text.ratingPromoterHeading}
          text={
            isConsultant
              ? text.ratingClientPromoterText
              : text.ratingConsultantPromoterText
          }
        />
      </div>
      <div
        style={{
          position: "relative",
          zIndex: 2,
          padding: "3rem",
          boxShadow: `0 0 0 1px ${color.LIGHT_GRAY}`,
          backgroundColor: "white",
          display: "grid",
          gridRowGap: "3rem",
        }}>
        <div>
          <div
            style={{
              fontWeight: 700,
              color: color.DARK_GRAY,
              fontSize: "1.125rem",
              marginBottom: "1.125rem",
            }}>
            {isConsultant
              ? text.ratingClientPrompt
              : text.ratingConsultantPrompt}
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(11, 1fr)",
              gridColumnGap: "0.125rem",
            }}>
            {Array.from({ length: 11 }).map((_, i) => {
              return (
                <div key={i}>
                  <div
                    onClick={() => setState(i)}
                    style={{
                      backgroundColor: color.LIGHT_GRAY,
                      cursor: "pointer",
                      height: "1rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}>
                    {state === i && (
                      <div
                        style={{
                          backgroundColor:
                            i >= 9
                              ? color.ORANGE
                              : i >= 7
                              ? color.BLUE
                              : color.FADED_BLACK,
                          width: "1.5rem",
                          height: "1.5rem",
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "white",
                          fontSize: "0.75rem",
                        }}>
                        {i}
                      </div>
                    )}
                  </div>
                  {(i === 0 || i === 5 || i === 10) && (
                    <div
                      style={{
                        textAlign: "center",
                        marginTop: "0.5rem",
                        color: color.DARK_GRAY,
                        fontSize: "1.125rem",
                      }}>
                      {i}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <Input
          label={
            state !== null && state < 7
              ? "Your complaint (will be sent to Guild)"
              : "Your review (could be publicly visible)"
          }
          value={review}
          onChange={e => setRreview(e.target.value)}
          type="text"
        />
        <div style={{ display: "flex" }}>
          <Button
            onClick={() => {
              store.rateConsultation(props.consultationId, props.id, {
                review,
                rating: state || 0,
              });
              window.scrollTo(0, 0);
            }}>
            {state !== null && state < 7
              ? "Submit rating & complaint"
              : "Submit rating & review"}
          </Button>
          <div
            style={{
              fontSize: "0.875rem",
              alignSelf: "center",
              opacity: 0.5,
              color: color.FADED_BLACK,
              marginLeft: "1rem",
            }}>
            You cannot change these
            <br />
            once you submit.
          </div>
        </div>
      </div>
    </TimelineBanner>
  );
};

export const TimelineItemEntry = (props: types.TimelineItem) => {
  switch (props.type) {
    case "REQUESTED":
      return <RequestedBanner {...props} />;

    case "MATCH":
      return <MatchedBanner {...props} />;

    case "MESSAGE":
      return <MessageEntry {...props} />;

    case "RATE_CONSULTANT":
      return <RatingEntry {...props} />;

    default:
      return null;
  }
};

type TimelineProps = {
  children: types.TimelineItem[];
};

export const TimelineView = (props: TimelineProps) => {
  const currentUser = useCurrentUser();

  return (
    <div style={{ paddingTop: "3rem" }}>
      {props.children.length === 0 ? (
        <TimelineBanner
          subtle
          heading="No items here (yet!)"
          text={template(
            "Once you have any correspondence with {OTHER_USER}, it will appear here.",
            {
              OTHER_USER:
                currentUser.type === "CLIENT"
                  ? "your consultant"
                  : "your client",
            },
          )}
        />
      ) : (
        props.children.map(t => {
          return <TimelineItemEntry key={t.id} {...t} />;
        })
      )}
    </div>
  );
};
