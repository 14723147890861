import React, { Fragment } from "react";
import { useHistory, useParams, generatePath } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import * as headings from "./headings";
import { HtmlText } from "./html-text";
import { InfoBox } from "./info-box";
import { useStore } from "./store-manager";
import { SecondaryNav, SecondaryNavLink } from "./secondary-nav";
import { Sidecar } from "./sidecar";
import { IconList } from "./icon-list";
import { Container } from "./container";
import { Topic, Consultant } from "./types";
import { TextList } from "./text-list";
import { TimelineSection } from "./timeline-section";
import { Separator } from "./separator";
import { Button } from "./buttons";
import { color, size } from "./stylesheet";
import { useCurrentUser } from "./user-view";
import { template, generateId } from "./utils";
import { path } from "./path";
import text from "./topic-detail-view.json";

type ConsultantHeaderProps = {
  portrait: string;
  name: string;
};

const ConsultantHeader = (props: ConsultantHeaderProps) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: "1.5rem 0",
      }}>
      <img
        src={props.portrait}
        alt=""
        style={{
          width: "2.5rem",
          height: "2.5rem",
          borderRadius: "50%",
          backgroundColor: color.VERY_LIGHT_GRAY,
          marginRight: "1rem",
        }}
      />
      <div
        style={{
          fontSize: "1.125rem",
          fontWeight: 700,
          color: color.DARK_GRAY,
        }}>
        {props.name}
      </div>
    </div>
  );
};

type OutputsSectionProps = {
  outputs: string[];
};

const OutputsSection = (props: OutputsSectionProps) => {
  return (
    <Fragment>
      <headings.MediumHeading
        id="outputs"
        marginBottom={size.MEDIUM_HEADING_SPACING}>
        {text.outputs_heading}
      </headings.MediumHeading>
      <HtmlText>{text.outputs_text}</HtmlText>
      <TextList>{props.outputs}</TextList>
      <InfoBox
        icon={text.outputs_quality_icon}
        text={text.outputs_quality_text}
      />
    </Fragment>
  );
};

type InputsSectionProps = {
  topicInputs: string[];
  companyInputs: string[];
};

const InputsSection = (props: InputsSectionProps) => {
  return (
    <Fragment>
      <headings.MediumHeading
        id="inputs"
        marginBottom={size.MEDIUM_HEADING_SPACING}>
        {text.inputs_heading}
      </headings.MediumHeading>
      <HtmlText>{text.inputs_text}</HtmlText>
      <headings.SmallHeading marginTop="1.5rem">
        {text.topic_inputs_heading}
      </headings.SmallHeading>
      <TextList>{props.topicInputs}</TextList>
      <headings.SmallHeading marginTop="1.5rem">
        {text.company_inputs_heading}
      </headings.SmallHeading>
      <TextList>{props.companyInputs}</TextList>
      <InfoBox
        icon={text.inputs_security_icon}
        text={text.inputs_security_text}
      />
    </Fragment>
  );
};

const StatsSection = (props: Topic) => {
  return (
    <Fragment>
      <headings.MediumHeading
        id="stats"
        marginBottom={size.MEDIUM_HEADING_SPACING}>
        {text.stats_heading}
      </headings.MediumHeading>
      <div
        style={{
          display: "grid",
          gridTemplate: "auto / repeat(6, 1fr)",
        }}>
        {props.highlights.map(highlight => {
          return (
            <div
              key={highlight.text}
              style={{ gridColumn: "span 2", paddingRight: "2rem" }}>
              <img
                src={`/icons/${highlight.icon}.svg`}
                alt=""
                style={{ width: "2.5rem", marginBottom: "1.125rem" }}
              />
              <HtmlText>{highlight.text}</HtmlText>
            </div>
          );
        })}
      </div>
    </Fragment>
  );
};

type ConsultantsSectionProps = {
  owner?: Consultant;
  consultants: Consultant[];
};

const ConsultantsSection = (props: ConsultantsSectionProps) => {
  if (!props.owner && props.consultants.length === 0) {
    return null;
  }

  return (
    <Fragment>
      <headings.MediumHeading
        id="consultants"
        marginBottom={size.MEDIUM_HEADING_SPACING}>
        {text.consultants_heading}
      </headings.MediumHeading>
      {props.owner && (
        <Fragment>
          <headings.SmallHeading marginBottom={size.SMALL_HEADING_SPACING}>
            {text.owner_heading}
          </headings.SmallHeading>
          <HtmlText>{text.owner_text}</HtmlText>
          <div
            style={{
              boxShadow: `0 1px 0 0 ${color.LIGHT_GRAY}, 0 -1px 0 0 ${color.LIGHT_GRAY}`,
              paddingBottom: "2rem",
              margin: "2rem 0 3rem",
            }}>
            <ConsultantHeader {...props.owner} />
            <div
              style={{
                display: "grid",
                gridTemplate: "auto / repeat(6, 1fr)",
              }}>
              <div
                style={{
                  display: "grid",
                  gridColumn: "span 2",
                  gridTemplateColumns: "1.25rem 1fr",
                  gridGap: "1rem",
                }}>
                {props.owner.highlights.map(highlight => {
                  return (
                    <Fragment key={highlight.icon}>
                      <img src={`/icons/${highlight.icon}.svg`} alt="" />
                      <div style={{ color: color.DARK_GRAY }}>
                        {highlight.text}
                      </div>
                    </Fragment>
                  );
                })}
              </div>
              <div
                style={{
                  gridColumn: "span 4",
                  paddingLeft: "3.5rem",
                  color: color.DARK_GRAY,
                }}
                dangerouslySetInnerHTML={{ __html: props.owner.bio }}
              />
            </div>
          </div>
        </Fragment>
      )}
      {props.consultants && props.consultants.length > 0 && (
        <Fragment>
          <headings.SmallHeading marginBottom={size.SMALL_HEADING_SPACING}>
            {text.active_consultants_heading}
          </headings.SmallHeading>
          <HtmlText>{text.active_consultants_text}</HtmlText>
          <div
            style={{
              display: "grid",
              gridTemplate: "auto / 1fr 1fr",
              gridColumnGap: "2rem",
              marginTop: "2rem",
            }}>
            {props.consultants.slice(0, 4).map(consultant => {
              return (
                <div
                  key={consultant.id}
                  style={{
                    padding: "2rem 0 3rem",
                    backgroundColor: "white",
                    boxShadow: `0 1px 0 0 ${color.LIGHT_GRAY}, 0 -1px 0 0 ${color.LIGHT_GRAY}`,
                  }}>
                  <ConsultantHeader {...consultant} />
                  <div style={{ color: color.DARK_GRAY, marginBottom: "1rem" }}>
                    {consultant.bio}
                  </div>
                  <div
                    style={{
                      display: "grid",
                      gridTemplate: "auto / 1.25rem 1fr",
                      gridGap: "1rem",
                    }}>
                    {consultant.highlights.map(highlight => {
                      return (
                        <Fragment key={highlight.icon}>
                          <img src={`/icons/${highlight.icon}.svg`} alt="" />
                          <div style={{ color: color.DARK_GRAY }}>
                            {highlight.text}
                          </div>
                        </Fragment>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

type PurchaseBoxProps = {
  price: number;
  info: { icon: string; text: string }[];
  onRequestClick: () => void;
};

const PurchaseBox = (props: PurchaseBoxProps) => {
  return (
    <Sidecar>
      <div style={{ color: "white" }}>
        <span style={{ fontSize: "2rem", fontWeight: 900 }}>
          ${props.price}
        </span>{" "}
        <span style={{ opacity: 0.5 }}>{text.per_consultation}</span>
      </div>

      <IconList marginBottom="2rem">{props.info}</IconList>

      <Button display="block" onClick={props.onRequestClick}>
        {text.request_consultation}
      </Button>
      <div
        style={{
          fontSize: "0.875rem",
          lineHeight: "1.125rem",
          color: color.VERY_LIGHT_GRAY,
          marginTop: "1rem",
          fontWeight: 300,
          textAlign: "center",
        }}>
        {text.no_charge_yet}
      </div>
    </Sidecar>
  );
};

type TopicCoverProps = {
  heading: string;
  tags: string[];
  text: string;
};

const TopicCover = (props: TopicCoverProps) => {
  return (
    <div
      style={{
        backgroundImage:
          "url(/images/illustration-2.svg), url(/images/illustration-3.svg)",
        backgroundRepeat: "no-repeat, no-repeat",
        backgroundPosition: "4% 55.9%, 103% 92%",
      }}>
      <div
        style={{
          display: "grid",
          gridTemplate: "auto / repeat(10, 1fr)",
          maxWidth: size.MAX_WIDTH,
          margin: "0 auto",
          padding: `0 ${size.BODY_PADDING}`,
        }}>
        <div
          style={{
            gridColumn: "3 / span 6",
            padding: "8.5rem 0",
            textAlign: "center",
          }}>
          <headings.LargeHeading>{props.heading}</headings.LargeHeading>
          <headings.Subheading>{props.tags.join(" • ")}</headings.Subheading>
          <HtmlText marginTop="2.875rem" marginBottom="1.5rem">
            {props.text}
          </HtmlText>
          <ScrollLink
            to="topic"
            offset={-88}
            smooth
            style={{
              fontSize: "1.125rem",
              fontWeight: 700,
              color: color.DARK_GRAY,
              cursor: "pointer",
            }}>
            {text.learn_more}
          </ScrollLink>
        </div>
      </div>
    </div>
  );
};

export const TopicDetailView = () => {
  const history = useHistory();
  const params = useParams<{ topicId: string }>();
  const store = useStore();
  const user = useCurrentUser();
  const topic = store.getTopicOrThrow(params.topicId);

  const topicOwner = store.getConsultant(topic.ownerId);
  const relatedConsultants = store
    .getTopicConsultants(topic.id)
    // Don't re-inclue the Topic Owner
    .filter(c => c.id !== topicOwner?.id);

  const sidebar = (
    <PurchaseBox
      price={topic.price}
      info={[
        {
          icon: "license-white",
          text: template(text.related_consultants, {
            CONSULTANTS_COUNT: store.getTopicConsultantsCount(topic.id),
          }),
        },
        { icon: "calendar-full-white", text: text.timelie_estimate },
      ]}
      onRequestClick={() => {
        const consultationId = generateId();
        store.createConsultation(user.id, topic.id, consultationId);
        history.push(generatePath(path.consultationDetail, { consultationId }));
      }}
    />
  );

  // prettier-ignore
  return (
    <Fragment>
      <TopicCover
        heading={topic.name}
        tags={topic.tags}
        text={topic.description}
      />
      <Container id="topic" sidebar={sidebar}>
        <SecondaryNav>
          <SecondaryNavLink label={text.outputs_heading} href="outputs" />
          <SecondaryNavLink label={text.inputs_heading} href="inputs" />
          {(topicOwner || relatedConsultants.length > 0) &&
            <SecondaryNavLink label={text.consultants_heading} href="consultants" />
          }
          <SecondaryNavLink label={text.timeline_heading} href="timeline" />
          <SecondaryNavLink label={text.stats_heading} href="stats" />
        </SecondaryNav>

        <OutputsSection outputs={topic.outputs.map(o => o.text)} />
        <Separator />

        <InputsSection
          topicInputs={topic.topicInputs.map(input => input.text)}
          companyInputs={topic.companyInputs.map(input => input.text)}
        />
        <Separator />

        <ConsultantsSection
          owner={topicOwner}
          consultants={relatedConsultants}
        />
        <Separator noLine />

        <TimelineSection
          heading={text.timeline_heading}
          text={text.timeline_text}
          stages={[
            { color: "#F7F7F7", text: text.timeline_matching_stage, days: 1 },
            { color: "#E3E3E3", text: text.timeline_response_stage, days: 3 },
            { color: "#C7C7C7", text: text.timeline_followups_stage, days: 3 },
          ]}
        />
        <Separator />

        <StatsSection {...topic} />
      </Container>
    </Fragment>
  );
};
