import React from "react";
import { HtmlText } from "./html-text";
import { color } from "./stylesheet";
import { pick } from "./utils";

type InputProps = {
  type: "text" | "text-short" | "files";
  label: string;
  hint?: string;
  optional?: boolean;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value: string;
};

export const Input = (props: InputProps) => {
  return (
    <label style={{ display: "block" }}>
      <span
        style={{
          display: "flex",
          fontWeight: 700,
          fontSize: "1.125rem",
          lineHeight: "1.5rem",
          marginBottom: "1.125rem",
          color: color.DARK_GRAY,
        }}>
        <span style={{ flex: "1 1 auto" }}>{props.label}</span>
        {props.optional && (
          <span style={{ flex: "0 0 auto", opacity: 0.6, fontWeight: 400 }}>
            (Optional)
          </span>
        )}
      </span>
      {props.hint && <HtmlText marginBottom="1.125rem">{props.hint}</HtmlText>}
      {props.type === "text" || props.type === "text-short" ? (
        <textarea
          value={props.value}
          onChange={props.onChange}
          style={{
            color: color.FADED_BLACK,
            fontSize: "1.125rem",
            lineHeight: "1.5rem",
            resize: "vertical",
            width: "calc(100% - 2rem)",
            minHeight: "1.5rem",
            height: pick(props.type, {
              text: "4.5rem",
              "text-short": "1.5rem",
            }),
            border: "none",
            padding: "1rem",
            boxShadow: `inset 0 0 0 1px ${color.LIGHT_GRAY}`,
          }}
        />
      ) : (
        <span
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: color.VERY_LIGHT_GRAY,
            padding: "1rem",
            minHeight: "8rem",
            cursor: "not-allowed"
          }}>
          <span style={{ textAlign: "center", color: color.DARK_GRAY }}>
            <span
              style={{
                display: "block",
                fontSize: "1.125rem",
                fontWeight: 700,
              }}>
              Drag & drop files here
            </span>
            <span style={{ display: "block", fontSize: "1.125rem" }}>
              or click to select
            </span>
          </span>
        </span>
      )}
    </label>
  );
};
