import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { LoginView } from "./login-view";
import { UserView } from "./user-view";
import { ErrorBoundary } from "./error-boundary";
import { StoreManager } from "./store-manager";
import { path } from "./path";
import { useResetScroll } from "./hooks";

const RootView = () => {
  useResetScroll();

  return (
    <Switch>
      <Route path={path.login} exact>
        <LoginView />
      </Route>
      <Route>
        <UserView />
      </Route>
    </Switch>
  );
};

export const App = () => {
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <StoreManager>
          <RootView />
        </StoreManager>
      </ErrorBoundary>
    </BrowserRouter>
  );
};
