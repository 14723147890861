import React, { ReactNode } from "react";
import dayjs from "dayjs";
import { MediumHeading, SmallHeading } from "./headings";
import { Link, generatePath } from "react-router-dom";
import { useHover } from "./hooks";
import { getRating, toNow } from "./formatters";
import { pick, template } from "./utils";
import { IconCircle } from "./icon-circle";
import { useStore } from "./store-manager";
import { MoreButton } from "./more-button";
import { size, color } from "./stylesheet";
import { path } from "./path";
import { useCurrentUser } from "./user-view";
import { Consultation } from "./types";
import { Portrait } from "./portrait";

type StatusTextProps = {
  children: ReactNode;
};

const StatusText = (props: StatusTextProps) => {
  return (
    <div
      style={{
        color: color.DARK_GRAY,
        opacity: 0.6,
        lineHeight: "1.5rem",
      }}>
      {props.children}
    </div>
  );
};

type RatingProps = {
  children?: number;
};

const Rating = (props: RatingProps) => {
  const category = getRating(props.children);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}>
      <IconCircle
        size="2.5rem"
        icon={pick(category, {
          unrated: "flag",
          promoter: "diamond-white",
          passive: "thumbs-up-white",
          detractor: "thumbs-down-white",
        })}
        backgroundColor={pick(category, {
          unrated: color.LIGHT_GRAY,
          promoter: color.ORANGE,
          passive: color.BLUE,
          detractor: color.FADED_BLACK,
        })}
      />
      <div
        style={{
          marginLeft: "1rem",
          color: color.DARK_GRAY,
          fontSize: "1.125rem",
          lineHeight: "1.5rem",
        }}>
        {pick(category, {
          unrated: "Ready for rating",
          promoter: "Excellent",
          passive: "Good",
          detractor: "Not Good",
        })}
      </div>
    </div>
  );
};

type Store = ReturnType<typeof useStore>;

type ClientStatusTextPayload = {
  consultation: Consultation;
  userName?: string;
};

export const getStatusText = (payload: ClientStatusTextPayload): string => {
  switch (payload.consultation.state) {
    case "MATCHED":
      if (payload.consultation.respondedDate) {
        const followupDeadlineDate = dayjs(payload.consultation.respondedDate)
          .add(3, "day")
          .fromNow();

        return template("{USER_NAME} • Consultation ends {DATE}", {
          USER_NAME: payload.userName || "Unknown",
          DATE: followupDeadlineDate,
        });
      }

      const responseDeadlineDate = dayjs(payload.consultation.matchedDate)
        .add(3, "day")
        .fromNow();

      return template("{USER_NAME} • Response due {DATE}", {
        USER_NAME: payload.userName || "Unknown",
        DATE: responseDeadlineDate,
      });

    case "REQUESTED":
      return "Looking for a consultant";

    case "DRAFT":
      const totalInputs = [
        ...payload.consultation.topicInputs,
        ...payload.consultation.companyInputs,
      ];

      return template(
        "Draft • {COMPLETED_COUNT} of {INPUTS_COUNT} questions answered",
        {
          COMPLETED_COUNT: `${
            totalInputs.filter(i => i.value.trim() !== "").length
          }`,
          INPUTS_COUNT: `${totalInputs.length}`,
        },
      );
    case "COMPLETED":
      return "";
  }
};

type ConsultationThumbProps = {
  heading: string;
  href: string;
  status: string;
  icon?: string;
  portrait?: string;
};

const ConsultationThumb = (props: ConsultationThumbProps) => {
  const { hovered, handlers } = useHover();

  return (
    <Link
      {...handlers}
      to={props.href}
      style={{
        display: "grid",
        gridTemplateColumns: "auto 1fr",
        gridColumnGap: "1.125rem",
        padding: "1.25rem 0",
        boxShadow: `0 1px 0 0 ${color.LIGHT_GRAY}, 0 -1px 0 0 ${color.LIGHT_GRAY}`,
        backgroundColor: "white",
      }}>
      {props.icon ? (
        <IconCircle
          icon={props.icon}
          size="3rem"
          backgroundColor={color.VERY_LIGHT_GRAY}
        />
      ) : (
        props.portrait && <Portrait portrait={props.portrait} size="3rem" />
      )}
      <div>
        <SmallHeading color={hovered ? color.RED : color.FADED_BLACK}>
          {props.heading}
        </SmallHeading>
        <StatusText>{props.status}</StatusText>
      </div>
    </Link>
  );
};

type ConsThumbGridProps = {
  children: ReactNode;
};

const ConsThumbGrid = (props: ConsThumbGridProps) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gridGap: "2rem",
      }}>
      {props.children}
    </div>
  );
};

type ConsultationRowProps = {
  heading: string;
  href: string;
  status: string;
  rating?: number;
  portrait?: string;
};

const ConsultationRow = (props: ConsultationRowProps) => {
  const { hovered, handlers } = useHover();

  return (
    <Link
      {...handlers}
      to={props.href}
      style={{
        display: "grid",
        gridTemplateColumns: "3rem 26rem 1fr 2.5rem",
        gridGap: "1rem",
        alignItems: "center",
        boxShadow: `0 1px 0 0 ${color.LIGHT_GRAY}, 0 -1px 0 0 ${color.LIGHT_GRAY}`,
        padding: "1.125rem 0",
        backgroundColor: "white",
      }}>
      <Portrait portrait={props.portrait} size="3rem" />

      <div>
        <SmallHeading color={hovered ? color.RED : color.FADED_BLACK}>
          {props.heading}
        </SmallHeading>
        <StatusText>{props.status}</StatusText>
      </div>

      <Rating>{props.rating}</Rating>
      <MoreButton />
    </Link>
  );
};

export const ConsultationsListingView = () => {
  const store = useStore();
  const user = useCurrentUser();
  const isClient = user.type === "CLIENT";

  const wipConsultations = isClient
    ? store.getClientWipConsultations(user.id)
    : store.getConsultantWipConsultations(user.id);

  const completeConsultations = isClient
    ? store.getClientCompleteConsultations(user.id)
    : store.getConsultantCompleteConsultations(user.id);

  return (
    <div
      style={{
        maxWidth: size.MAX_WIDTH,
        margin: "0 auto",
        padding: `2rem ${size.BODY_PADDING} 12rem`,
        display: "grid",
        gridGap: "4.25rem",
      }}>
      {wipConsultations.length === 0 && completeConsultations.length === 0 && (
        <div>You have no consultations</div>
      )}

      {wipConsultations.length > 0 && (
        <div>
          <MediumHeading marginBottom="2rem">Active</MediumHeading>
          <ConsThumbGrid>
            {wipConsultations.map(consultation => {
              const href = generatePath(path.consultationDetail, {
                consultationId: consultation.id,
              });

              if (consultation.state === "DRAFT") {
                return (
                  <ConsultationThumb
                    key={consultation.id}
                    heading={consultation.name}
                    icon="pencil"
                    status={getStatusText({ consultation })}
                    href={href}
                  />
                );
              }

              if (consultation.state === "REQUESTED") {
                return (
                  <ConsultationThumb
                    heading={consultation.name}
                    icon="magnifier"
                    status={getStatusText({ consultation })}
                    href={href}
                  />
                );
              }

              const user = store.getUser(
                isClient ? consultation.consultantId : consultation.clientId,
              );

              return (
                <ConsultationThumb
                  heading={consultation.name}
                  portrait={user?.portrait}
                  status={getStatusText({
                    consultation,
                    userName: user?.name,
                  })}
                  href={href}
                />
              );
            })}
          </ConsThumbGrid>
        </div>
      )}

      {completeConsultations.length > 0 && (
        <div>
          <MediumHeading marginBottom="2rem">Completed</MediumHeading>
          {completeConsultations.map(consultation => {
            const user = isClient
              ? store.getConsultant(consultation.consultantId)
              : store.getClient(consultation.clientId);

            return (
              <ConsultationRow
                key={consultation.id}
                heading={consultation.name}
                portrait={user?.portrait}
                status={`${user?.name} • ${toNow(consultation.completedDate)}`}
                rating={consultation.rating}
                href={generatePath(path.consultationDetail, {
                  consultationId: consultation.id,
                })}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
