import React, { ReactNode } from "react";
import { TopNav } from "./top-nav";
import { Footer } from "./footer";

type LayoutProps = {
  children: ReactNode;
};

export const Layout = (props: LayoutProps) => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <TopNav />

      <main style={{ flexGrow: 1 }}>{props.children}</main>
      <Footer />
    </div>
  );
};
