import React from "react";
import { size, color } from "./stylesheet";
import { LargeHeading, MediumHeading } from "./headings";
import { HtmlText } from "./html-text";
import { Portrait } from "./portrait";
import { useStore } from "./store-manager";
import { Link, generatePath } from "react-router-dom";
import { path } from "./path";
import { useHover } from "./hooks";
import { when, template } from "./utils";
import { IconCircle } from "./icon-circle";
import { toNow } from "./formatters";
import text from "./opportunities-listing-view.json";

type ListControlProps = {
  label: string;
  selection: string;
  icon: string;
};

const ListControl = (props: ListControlProps) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginRight: "2rem",
        cursor: "not-allowed",
      }}>
      <img
        src={`/icons/${props.icon}.svg`}
        alt=""
        style={{ marginRight: "0.5rem" }}
      />
      <div style={{ color: color.DARK_GRAY }}>
        {props.label}: <span>{props.selection}</span>
      </div>
    </div>
  );
};

type OpportunityRowProps = {
  id: string;
  clientId: string;
  name: string;
  requestedDate: number;
  consultantFee: number;
};

const OpportunityRow = (props: OpportunityRowProps) => {
  const { hovered, handlers } = useHover();
  const store = useStore();
  const user = store.getUser(props.clientId);

  if (!user) {
    return null;
  }

  return (
    <Link
      {...handlers}
      to={generatePath(path.opportunityDetail, { opId: props.id })}
      style={{
        display: "flex",
        padding: "1.5rem 0",
        boxShadow: `0 1px 0 0 ${color.LIGHT_GRAY}, 0 -1px 0 0 ${color.LIGHT_GRAY}`,
        backgroundColor: "white",
      }}>
      <Portrait userId={props.clientId} size="3.5rem" marginRight="1rem" />
      <div style={{ flexGrow: 1 }}>
        <MediumHeading color={when(hovered, color.RED)}>
          <span style={{ marginRight: when(user.rating >= 9, "1rem") }}>
            {user.name}
          </span>
          {user.rating >= 9 && (
            <IconCircle
              size="2rem"
              icon="diamond-white"
              backgroundColor={color.ORANGE}
              display="inline-flex"
            />
          )}
          <span
            style={{
              color: color.DARK_GRAY,
              opacity: hovered ? 0.6 : 0.5,
              fontWeight: 400,
              marginLeft: "1rem",
            }}>
            {props.name}
          </span>
        </MediumHeading>
        <div
          style={{
            opacity: 0.6,
            color: color.DARK_GRAY,
            lineHeight: "1.5rem",
          }}>
          {template("Requested {DATE}", {
            DATE: toNow(props.requestedDate),
          })}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "2rem",
          fontWeight: 900,
        }}>
        ${props.consultantFee}
      </div>
    </Link>
  );
};

export const OpportunitiesListingView = () => {
  const store = useStore();
  const opportunities = store.getOpportunities();

  return (
    <div
      style={{
        maxWidth: size.MAX_WIDTH,
        margin: "0 auto",
        padding: `4.25rem ${size.BODY_PADDING} 12rem`,
      }}>
      <div style={{ marginBottom: "4.25rem" }}>
        <LargeHeading marginBottom="1rem">
          {text.opportunities_listing_header}
        </LargeHeading>
        <HtmlText>{text.opportunities_listing_text}</HtmlText>
      </div>
      <div>
        <div style={{ display: "flex", marginBottom: "2rem" }}>
          <ListControl label="Sort by" selection="Recent" icon="line-spacing" />
          <ListControl label="Filter" selection="None" icon="funnel" />
        </div>
        {opportunities.map(opportunity => {
          return <OpportunityRow key={opportunity.id} {...opportunity} />;
        })}
      </div>
    </div>
  );
};
