import React, { ReactNode } from "react";
import { Link, NavLink } from "react-router-dom";
import { MediumHeading } from "./headings";
import { size, color } from "./stylesheet";
import { useHover } from "./hooks";
import { MoreButton } from "./more-button";

type ConsultationHeaderLinkProps = {
  label: string;
  href: string;
};

export const ConsultationHeaderLink = (props: ConsultationHeaderLinkProps) => {
  const { hovered, handlers } = useHover();

  return (
    <NavLink
      {...handlers}
      to={props.href}
      style={{
        fontSize: "1.125rem",
        lineHeight: "1.125rem",
        color: hovered ? color.RED : color.DARK_GRAY,
        marginRight: "1rem",
      }}
      activeStyle={{ color: color.RED }}>
      {props.label}
    </NavLink>
  );
};

type ConsultationHeaderProps = {
  heading: string;
  backHref: string;
  children: ReactNode;
  statusText?: string;
  statusIcon?: string;
};

export const ConsultationHeader = (props: ConsultationHeaderProps) => {
  const { hovered, handlers } = useHover();
  return (
    <div
      style={{
        zIndex: 5,
        maxWidth: size.MAX_WIDTH,
        margin: "0 auto",
        padding: `0 ${size.BODY_PADDING}`,
        position: "relative",
        backgroundColor: "white",
      }}>
      <Link
        {...handlers}
        to={props.backHref}
        style={{
          position: "absolute",
          top: 0,
          left: "-1rem",
          bottom: 0,
          display: "flex",
          alignItems: "center",
          opacity: hovered ? 0.5 : 0.3,
        }}>
        <svg
          width="24px"
          height="40px"
          viewBox="0 0 24 40"
          version="1.1"
          style={{
            transform: `translateX(${hovered ? "-0.5rem" : "0"})`,
          }}>
          <polygon
            fill="#616161"
            fillRule="nonzero"
            points="16.8263855 6.1 19.1117379 8.04349279 8.93706172 20.0057464 19.1117379 31.9697917 16.8263855 33.9132845 5 20.0066422"></polygon>
        </svg>
      </Link>
      <div
        style={{
          boxShadow: `0 1px 0 0 ${color.LIGHT_GRAY}`,
          padding: "2rem 0",
          display: "flex",
          alignItems: "center",
        }}>
        <div style={{ flex: "1 0 auto" }}>
          <MediumHeading>{props.heading}</MediumHeading>
          <div
            style={{
              display: "flex",
              marginTop: "0.875rem",
              alignItems: "center",
            }}>
            {props.children}
          </div>
        </div>

        {props.statusText && (
          <div
            style={{
              backgroundColor: color.VERY_LIGHT_GRAY,
              color: color.DARK_GRAY,
              marginRight: "1rem",
              padding: "0.9375rem",
              lineHeight: "1.25rem",
              borderRadius: "0.125rem",
              display: "flex",
              alignItems: "center",
            }}>
            <img
              src={`/icons/${props.statusIcon}.svg`}
              alt=""
              style={{ marginRight: "1rem" }}
            />
            {props.statusText}
          </div>
        )}
        <MoreButton size="large" />
      </div>
    </div>
  );
};
