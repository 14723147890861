import React from "react";
import { color, size } from "./stylesheet";
import { Link } from "react-router-dom";
import text from "./footer.json";

export const Footer = () => {
  return (
    <div
      style={{
        backgroundColor: color.FADED_BLACK,
        padding: "8rem 2rem 10rem",
      }}>
      <div
        style={{
          display: "grid",
          gridTemplate: "auto / repeat(5, 1fr)",
          maxWidth: size.MAX_WIDTH,
          boxSizing: "border-box",
          margin: "0 auto",
          color: "white",
          fontSize: "1.125rem",
        }}>
        {[
          {
            heading: text.column_1_heading,
            links: text.column_1_links,
          },
          {
            heading: text.column_2_heading,
            links: text.column_2_links,
          },
          {
            heading: text.column_3_heading,
            links: text.column_3_links,
          },
          {
            heading: text.column_4_heading,
            links: text.column_4_links,
          },
          {
            heading: text.contact_heading,
            text: text.contact_text,
          },
        ].map((column, i) => {
          return (
            <div key={i}>
              <div
                style={{
                  textTransform: "uppercase",
                  marginBottom: "1.5rem",
                  fontWeight: 700,
                }}>
                {column.heading}
              </div>
              {column.links?.map((link, i) => (
                <Link key={i} to={link.href} style={{
                  display: "block",
                  margin: "1rem 0"
                }}>
                  {link.label}
                </Link>
              ))}
              {column.text && (
                <div dangerouslySetInnerHTML={{ __html: column.text }} />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
