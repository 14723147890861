import React from "react";
import { color } from "./stylesheet";
import { HtmlText } from "./html-text";
import { IconCircle } from "./icon-circle";

type InfoBoxProps = {
  icon: string;
  text: string;
  fontSize?: "tiny" | "small" | "medium";
};

export const InfoBox = (props: InfoBoxProps) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplate: "auto / 3rem 1fr",
        gridGap: "1rem",
        padding: "1rem",
        backgroundColor: color.VERY_LIGHT_GRAY,
        marginTop: "2rem",
        alignItems: "center",
      }}>
      <IconCircle icon={props.icon} backgroundColor="white" />
      <HtmlText fontSize={props.fontSize}>{props.text}</HtmlText>
    </div>
  );
};
