import React from "react";
import { generatePath } from "react-router-dom";
import { InfoView } from "./info-views";
import { path } from "./path";
import { useConsultation } from "./consultation-detail-view";
import text from "./consultation-accepted-view.json";

export const ConsultationAcceptedView = () => {
  const consultation = useConsultation();

  return (
    <InfoView
      icon="briefcase"
      heading={text.heading}
      text={text.text}
      links={[
        {
          label: text.view_consultation,
          href: generatePath(path.consultationDetail, {
            consultationId: consultation.id,
          }),
        },
      ]}
    />
  );
};
