import React, { ReactNode, Fragment } from "react";
import { Link, generatePath } from "react-router-dom";
import { LargeHeading, MediumHeading } from "./headings";
import { color, size } from "./stylesheet";
import { useHover } from "./hooks";
import { useStore } from "./store-manager";
import { template } from "./utils";
import { path } from "./path";
import text from "./topics-listing-view.json";

type TextColumnProps = {
  children: string;
};

const TextColumn = (props: TextColumnProps) => {
  if (props.children === "") {
    return null;
  }

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gridColumnGap: "2rem",
      }}>
      <div
        dangerouslySetInnerHTML={{ __html: props.children }}
        style={{
          gridColumn: "span 2",
          color: color.DARK_GRAY,
          fontSize: "1.125rem",
        }}
      />
    </div>
  );
};

type TopicsGridProps = {
  children: ReactNode;
};

const TopicsGrid = (props: TopicsGridProps) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gridColumnGap: "4rem",
        margin: "3rem 0",
      }}>
      {props.children}
    </div>
  );
};

type CallToActionProps = {
  children: string;
};

const CallToAction = (props: CallToActionProps) => {
  return (
    <div
      style={{
        fontSize: "1.125rem",
        textDecoration: "underline",
        color: color.DARK_GRAY,
        cursor: "not-allowed",
      }}>
      {props.children}
    </div>
  );
};

type TopicThumbProps = {
  name: string;
  href: string;
  description: string;
  price: number;
  status: string;
};

const TopicThumb = (props: TopicThumbProps) => {
  const { hovered, handlers } = useHover();

  return (
    <Link
      {...handlers}
      to={props.href}
      style={{
        display: "flex",
        flexDirection: "column",
        boxShadow: `0 1px 0 0 ${color.LIGHT_GRAY}, 0 -1px 0 0 ${color.LIGHT_GRAY}`,
        padding: "3rem 0",
        backgroundColor: "white",
      }}>
      <div style={{ flexGrow: 1 }}>
        <MediumHeading
          color={hovered ? color.RED : color.FADED_BLACK}
          marginBottom="1.5rem">
          {props.name}
        </MediumHeading>
      </div>
      <div
        style={{
          marginBottom: "1.5rem",
          color: color.DARK_GRAY,
          fontSize: "1.125rem",
        }}>
        {props.description}
      </div>
      <div style={{ color: color.DARK_GRAY, fontSize: "1.125rem" }}>
        <span style={{ fontWeight: 700 }}>${props.price}</span>
        {" • "}
        {props.status}
      </div>
    </Link>
  );
};

export const TopicsListingView = () => {
  const store = useStore();

  return (
    <Fragment>
      <div
        style={{
          backgroundImage: "url(/images/illustration-1.svg)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "102.7% 131.9%",
        }}>
        <div
          style={{
            maxWidth: size.MAX_WIDTH,
            margin: "0 auto",
            padding: `0 ${size.BODY_PADDING}`,
          }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              boxShadow: `0 1px 0 0 ${color.LIGHT_GRAY}`,
              padding: "6rem 0",
            }}>
            <div
              style={{
                position: "relative",
                width: "36rem",
                borderRadius: "0.1875rem",
                border: `1px solid ${color.LIGHT_GRAY}`,
                padding: "1.75rem",
                fontSize: "1.5rem",
                fontWeight: 300,
                boxShadow: "0 0.5rem 1.5rem rgba(0,0,0,0.05)",
                backgroundColor: "white",
                textAlign: "center",
                color: color.DARK_GRAY,
                cursor: "not-allowed",
              }}>
              {text.search_input_placeholder}
              <div
                style={{
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  top: 0,
                  left: 0,
                  bottom: 0,
                  width: "5.5rem",
                }}>
                <img src="/icons/magnifier.svg" alt="" />
              </div>
            </div>
            <div
              style={{
                fontSize: "1.125rem",
                textDecoration: "underline",
                color: color.DARK_GRAY,
                marginTop: "1.5rem",
                cursor: "not-allowed",
              }}>
              {text.search_hint}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "minmax(0, 70rem)",
          gridGap: "8rem",
          maxWidth: size.MAX_WIDTH,
          margin: "0 auto",
          padding: `8rem ${size.BODY_PADDING} 12rem`,
        }}>
        <div>
          <LargeHeading marginBottom="1rem">
            {text.recommendations_heading}
          </LargeHeading>
          <TextColumn>{text.recommendations_text}</TextColumn>
          <TopicsGrid>
            {store.getNTopics(3).map((topic, i) => {
              return (
                <TopicThumb
                  key={i}
                  href={generatePath(path.topicDetail, { topicId: topic.id })}
                  name={topic.name}
                  description={topic.shortDescription}
                  price={topic.price}
                  status={template("{COUNT} consultants available", {
                    COUNT: store.getTopicConsultantsCount(topic.id),
                  })}
                />
              );
            })}
          </TopicsGrid>
          <CallToAction>{text.recommendations_call_to_action}</CallToAction>
        </div>
        <div>
          <LargeHeading marginBottom="1rem">{text.kits_heading}</LargeHeading>
          <TextColumn>{text.kits_text}</TextColumn>
          <img
            src="/images/kit-banner.png"
            alt=""
            style={{
              width: "100%",
              margin: "3rem 0",
              borderRadius: "1.125rem",
              backgroundColor: color.VERY_LIGHT_GRAY,
              cursor: "not-allowed",
            }}
          />
          <CallToAction>{text.kits_call_to_action}</CallToAction>
        </div>
        <div>
          <LargeHeading marginBottom="1rem">
            {text.discover_heading}
          </LargeHeading>
          <TextColumn>{text.discover_text}</TextColumn>
          <TopicsGrid>
            {store.getNTopics(9).map((topic, i) => {
              return (
                <TopicThumb
                  key={i}
                  href={generatePath(path.topicDetail, { topicId: topic.id })}
                  name={topic.name}
                  description={topic.shortDescription}
                  price={topic.price}
                  status={template("{COUNT} consultants available", {
                    COUNT: store.getTopicConsultantsCount(topic.id),
                  })}
                />
              );
            })}
          </TopicsGrid>
        </div>
      </div>
    </Fragment>
  );
};
