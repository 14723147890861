import React from "react";
import { IconCircle } from "./icon-circle";
import { HtmlText } from "./html-text";

type IconListProps = {
  children: { icon: string; text: string }[];
  marginBottom?: string;
};

export const IconList = (props: IconListProps) => {
  return (
    <div style={{ marginBottom: props.marginBottom }}>
      {props.children.map(info => {
        return (
          <div
            key={info.text}
            style={{
              display: "grid",
              gridTemplateColumns: "3rem 1fr",
              gridGap: "1rem",
              marginBottom: "1.125rem",
              alignItems: "center",
            }}>
            <IconCircle size="3rem" icon={info.icon} />
            <HtmlText color="white" fontSize="tiny">
              {info.text}
            </HtmlText>
          </div>
        );
      })}
    </div>
  );
};
