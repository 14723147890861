import React, { createContext, useContext } from "react";
import { Route, useParams, Switch, Redirect } from "react-router-dom";
import { ConsultationDraftView } from "./consultation-draft-view";
import { useStore } from "./store-manager";
import { ConsultationRequestedView } from "./consultation-requested-view";
import { ConsultationTimelineView } from "./consultation-timeline-view";
import { Consultation } from "./types";
import { path } from "./path";
import { ConsultationAcceptedView } from "./consultation-accepted-view";

const ConsultationContext = createContext<Consultation | undefined>(undefined);

export const useConsultation = () => {
  const consultation = useContext(ConsultationContext);

  if (!consultation) {
    throw new Error("Couldn't find consuldation in context");
  }

  return consultation;
};

export const ConsultationDetailView = () => {
  const store = useStore();
  const params = useParams<{ consultationId: string }>();
  const consultation = store.getConsultationOrThrow(params.consultationId);

  return (
    <ConsultationContext.Provider value={consultation}>
      {consultation.state === "DRAFT" ? (
        <Switch>
          <Route path={path.consultationDraft}>
            <ConsultationDraftView />
          </Route>
          <Redirect to={path.consultationDraft} />
        </Switch>
      ) : (
        <Switch>
          <Route path={path.consultationRequested} exact>
            <ConsultationRequestedView />
          </Route>
          <Route path={path.consultationAccepted} exact>
            <ConsultationAcceptedView />
          </Route>
          <Route>
            <ConsultationTimelineView />
          </Route>
        </Switch>
      )}
    </ConsultationContext.Provider>
  );
};
