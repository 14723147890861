/**
 * A no-operation function.
 *
 * Useful in places where a function is necessary for type safety, but
 * you don't want it to do anything when actually called.
 */
export const noop = () => {
  console.warn("You called a noop function.");
};

/**
 * Functional alternative to a switch/case statement.
 *
 * Note that unlike a switch/case, a pick call actually evaluates all its
 * values (branches). Don't use it for expensive operations or anything
 * that has side-effects.
 */

export const pick = <Map, Key extends keyof Map>(key: Key, map: Map) => {
  return map[key];
};

/**
 * Functional alternative to `test ? value : undefined`
 *
 * Slightly more ergonomic and intentional than the above mentioned ternary.
 * Mostly used in style objects, when a value should be present only if a
 * certain prop is defined.
 */
export const when = <T>(test: boolean | null | undefined, value: T) => {
  if (test) {
    return value;
  }

  return undefined;
};

/**
 * Simple text-replacement templating
 *
 * Not a replacement for full localization/i18n strategy, but allows for simple
 * variable-based templating.
 */

export const template = (
  text: string,
  replace: { [k: string]: string | number },
) => {
  const pairs = Object.entries(replace);

  for (const [key, value] of pairs) {
    const search = new RegExp("{\\w*" + key + "\\w*}", "g");
    text = text.replace(search, value.toString());
  }

  return text;
};

export const assert = <T>(entity: string, value: T | null | undefined): T => {
  if (!value) {
    const error = new Error(`Coulnd't retrieve ${entity} from store.`);
    error.name = "Not Found Error";
    throw error;
  }

  return value;
};

// Adapted from:
// https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript

function dec2hex(decimal: number) {
  return ("0" + decimal.toString(16)).substr(-2);
}

export function generateId() {
  var arr = new Uint8Array(4); // this value is doubled in the final output
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join("");
}
