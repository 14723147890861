import React, { ReactNode, useState, Fragment } from "react";
import { Button, LinkButton } from "./buttons";
import { color } from "./stylesheet";
import { Portrait } from "./portrait";
import { useCurrentUser } from "./user-view";
import { useStore } from "./store-manager";
import { useParams, generatePath, Link, useHistory } from "react-router-dom";
import { path } from "./path";
import { HtmlText } from "./html-text";
import { toNow } from "./formatters";
import { TextList } from "./text-list";
import { SmallHeading } from "./headings";
import { IconCircle } from "./icon-circle";
import { serializeConsultationInputs } from "./creators";
import { EmailMessageItem } from "./types";

type TabLabelProps = {
  active?: boolean;
  children: ReactNode;
  onClick: () => void;
};

const TabLabel = (props: TabLabelProps) => {
  return (
    <div
      onClick={props.onClick}
      style={{
        fontSize: "1rem",
        color: props.active ? color.RED : color.DARK_GRAY,
        lineHeight: "1.25rem",
        marginRight: "1rem",
        cursor: "pointer",
      }}>
      {props.children}
    </div>
  );
};

type IconGroupProps = {
  children: ReactNode;
};

const IconGroup = (props: IconGroupProps) => {
  return (
    <div
      style={{
        display: "grid",
        gridAutoFlow: "column",
        gridGap: "0.5rem",
        marginRight: "1.5rem",
      }}>
      {props.children}
    </div>
  );
};

export const MessageComposerView = () => {
  const params = useParams<{ consultationId: string }>();
  const history = useHistory();
  const store = useStore();
  const currentUser = useCurrentUser();
  const consultation = store.getConsultationOrThrow(params.consultationId);
  const [tab, setTab] = useState<
    "CORRESPONDENCE" | "INPUTS" | "OUTPUTS" | "ABOUT"
  >("CORRESPONDENCE");

  const [value, setValue] = useState("");

  if (consultation.state === "DRAFT" || consultation.state === "REQUESTED") {
    throw new Error("Consultation in the wrong state");
  }

  const otherUser =
    currentUser.type === "CLIENT"
      ? store.getConsultantOrThrow(consultation.consultantId)
      : store.getClientOrThrow(consultation.clientId);

  const consultationDetailPath = generatePath(path.consultationDetail, {
    consultationId: consultation.id,
  });

  const messages = consultation.timeline.filter(
    item => item.type === "MESSAGE",
  ) as EmailMessageItem[];

  return (
    <div
      style={{
        height: "100vh",
        display: "grid",
        gridTemplateRows: "auto 1fr auto",
      }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1rem",
          boxShadow: `0 1px 0 0 ${color.LIGHT_GRAY}`,
          alignItems: "center",
        }}>
        <SmallHeading>Message to {otherUser.name}</SmallHeading>
        <Link to={consultationDetailPath} style={{ padding: "0.5rem" }}>
          <img src="/icons/cross.svg" alt="" style={{ display: "block" }} />
        </Link>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "3fr 2fr",
        }}>
        <div
          style={{
            display: "grid",
            gridTemplateRows: "auto 1fr",
          }}>
          <div style={{ padding: "0 1rem" }}>
            <div
              style={{
                padding: "1rem 0",
                display: "flex",
                boxShadow: `0 1px 0 0 ${color.LIGHT_GRAY}`,
              }}>
              <IconGroup>
                <img src="/icons/bold.svg" alt="" />
                <img src="/icons/italic.svg" alt="" />
                <img src="/icons/link.svg" alt="" />
              </IconGroup>
              <IconGroup>
                <img src="/icons/list.svg" alt="" />
                <img src="/icons/indent-increase.svg" alt="" />
                <img src="/icons/indent-decrease.svg" alt="" />
              </IconGroup>
              <img src="/icons/file-add.svg" alt="" />
            </div>
          </div>
          <textarea
            autoFocus
            value={value}
            onChange={event => {
              setValue(event.target.value);
            }}
            style={{
              padding: "1rem",
              border: "none",
              resize: "block",
              width: "100%",
              height: "100%",
              boxSizing: "border-box",
            }}
          />
        </div>
        <div
          style={{
            boxShadow: `-1px 0 0 0 ${color.LIGHT_GRAY}`,
            height: "calc(100vh - 4.25rem - 5.21875rem)",
            overflow: "auto",
          }}>
          <div style={{ padding: "0 1rem" }}>
            <div
              style={{
                padding: "1rem 0",
                display: "flex",
                boxShadow: `0 1px 0 0 ${color.LIGHT_GRAY}`,
              }}>
              <TabLabel
                onClick={() => setTab("CORRESPONDENCE")}
                active={tab === "CORRESPONDENCE"}>
                Correspondence
              </TabLabel>
              <TabLabel
                onClick={() => setTab("INPUTS")}
                active={tab === "INPUTS"}>
                Inputs
              </TabLabel>
              <TabLabel
                onClick={() => setTab("OUTPUTS")}
                active={tab === "OUTPUTS"}>
                Outputs
              </TabLabel>
              <TabLabel
                onClick={() => setTab("ABOUT")}
                active={tab === "ABOUT"}>
                About {otherUser.name}
              </TabLabel>
            </div>
          </div>
          <div
            style={{
              padding: "0 1rem",
            }}>
            {tab === "ABOUT" && (
              <div style={{ paddingTop: "1rem" }}>
                <div
                  style={{
                    display: "flex",
                    marginBottom: "1.5rem",
                    alignItems: "center",
                  }}>
                  <Portrait
                    portrait={otherUser.portrait}
                    marginRight="1rem"
                    size="2.25rem"
                  />
                  <SmallHeading>{otherUser.name}</SmallHeading>
                </div>
                <HtmlText fontSize="small">{otherUser.bio}</HtmlText>
                <div
                  style={{
                    marginTop: "2rem",
                    display: "grid",
                    gridTemplateColumns: "1.25rem 1fr",
                    gridGap: "1rem",
                  }}>
                  {otherUser.highlights.map(highlight => {
                    return (
                      <Fragment key={highlight.icon}>
                        <img src={`/icons/${highlight.icon}.svg`} alt="" />
                        <div style={{ color: color.DARK_GRAY }}>
                          {highlight.text}
                        </div>
                      </Fragment>
                    );
                  })}
                </div>
              </div>
            )}
            {tab === "INPUTS" && (
              <div style={{ paddingTop: "1rem" }}>
                <SmallHeading marginBottom="1.5rem">Inputs</SmallHeading>
                <HtmlText fontSize="small">
                  {serializeConsultationInputs(consultation)}
                </HtmlText>
              </div>
            )}
            {tab === "OUTPUTS" && (
              <div style={{ paddingTop: "1rem" }}>
                <SmallHeading marginBottom="1.5rem">Outputs</SmallHeading>
                <TextList fontSize="small">
                  {consultation.outputs.map(o => o.text)}
                </TextList>
              </div>
            )}
            {tab === "CORRESPONDENCE" &&
              (messages.length === 0 ? (
                <div
                  style={{
                    padding: "3rem",
                    opacity: 0.5,
                    textAlign: "center",
                  }}>
                  <SmallHeading>No correspondence yet</SmallHeading>
                </div>
              ) : (
                messages.map(item => {
                  return (
                    <div
                      style={{
                        paddingBottom: "1rem",
                        boxShadow: `0 1px 0 0 ${color.LIGHT_GRAY}`,
                      }}>
                      <div
                        style={{
                          padding: "1rem 0",
                          display: "grid",
                          gridTemplateColumns: "auto 1fr",
                        }}>
                        <Portrait
                          userId={item.senderId}
                          marginRight="1rem"
                          size="2.25rem"
                        />
                        <div>
                          <div
                            style={{
                              color: color.FADED_BLACK,
                              fontWeight: 700,
                            }}>
                            {item.subject}
                          </div>
                          <div
                            style={{
                              color: color.DARK_GRAY,
                              opacity: 0.5,
                              fontSize: "0.875rem",
                              lineHeight: "0.875rem",
                            }}>
                            {item.senderId === currentUser.id
                              ? "You"
                              : otherUser.name}
                            {" • "}
                            {toNow(item.createdDate)}
                          </div>
                        </div>
                      </div>
                      <HtmlText fontSize="tiny">{item.message}</HtmlText>
                      {item.attachemnts && item.attachemnts?.length !== 0 && (
                        <div
                          style={{
                            marginTop: "2rem",
                            display: "grid",
                            gridTemplateColumns: "repeat(2, 1fr)",
                            gridGap: "1rem",
                          }}>
                          {item.attachemnts?.map(attachment => {
                            return (
                              <div
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "auto 1fr",
                                  gridColumnGap: "1rem",
                                  backgroundColor: color.VERY_LIGHT_GRAY,
                                  borderRadius: "0.25rem",
                                  padding: "0.75rem",
                                }}>
                                <IconCircle
                                  icon="paperclip"
                                  backgroundColor="white"
                                />
                                <div>
                                  <div
                                    style={{
                                      color: color.FADED_BLACK,
                                      fontWeight: 700,
                                      fontSize: "0.875rem",
                                    }}>
                                    {attachment.name}
                                  </div>
                                  <div
                                    style={{
                                      color: color.DARK_GRAY,
                                      opacity: 0.5,
                                      fontSize: "0.75rem",
                                    }}>
                                    {attachment.size}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  );
                })
              ))}
          </div>
        </div>
      </div>
      <div
        style={{
          padding: "1rem",
          display: "flex",
          justifyContent: "space-between",
          boxShadow: `0 -1px 0 0 ${color.LIGHT_GRAY}`,
        }}>
        <LinkButton theme="white" to={consultationDetailPath}>
          Discard
        </LinkButton>
        <Button
          theme="blue"
          onClick={() => {
            store.sendMessage(consultation.id, {
              subject: "Message",
              message: value
                .split(/\n\n+/g)
                .map(line => `<p>${line}</p>`)
                .join("")
                .replace("\n", "<br/>"),
              senderId: currentUser.id,
            });
            history.push(
              generatePath(path.consultationTimeline, {
                consultationId: consultation.id,
              }),
            );
          }}>
          Send
        </Button>
      </div>
    </div>
  );
};
