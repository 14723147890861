import { useState, useLayoutEffect, useRef, MutableRefObject } from "react";
import { useLocation } from "react-router-dom";

export function useHover() {
  const [state, setState] = useState(false);
  return {
    hovered: state,
    handlers: {
      onMouseEnter: () => setState(true),
      onMouseLeave: () => setState(false),
    },
  };
}

export function useResetScroll() {
  const location = useLocation();
  useLayoutEffect(() => window.scrollTo(0, 0), [location.pathname]);
}

// Inspired by https://github.com/Swizec/useDimensions
export function useDimensions<Element extends HTMLElement>(): [
  MutableRefObject<Element | null>,
  DOMRect | null,
] {
  const ref = useRef<Element | null>(null);
  const [dimensions, setDimensions] = useState<DOMRect | null>(null);

  useLayoutEffect(() => {
    if (ref.current) {
      setDimensions(ref.current.getBoundingClientRect().toJSON());
    }
  }, [ref]);

  return [ref, dimensions];
}
