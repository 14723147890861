import React, { createContext, useContext, useState } from "react";
import { initialState } from "./initial-state";
import * as types from "./types";
import * as creators from "./creators";
import { assert } from "./utils";

export type State = {
  clients: types.Client[];
  consultants: types.Consultant[];
  topics: types.Topic[];
  draftConsultations: types.DraftConsultation[];
  requestedConsultations: types.RequestedConsultation[];
  matchedConsultations: types.MatchedConsultation[];
  completeConsultations: types.CompleteConsultation[];
};

type Context = {
  state: State;
  setState: React.Dispatch<React.SetStateAction<State>>;
};

export const StateContext = createContext<Context | null>(null);

type StoreManagerProps = {
  children: React.ReactNode;
};

export const StoreManager = (props: StoreManagerProps) => {
  const [state, setState] = useState(initialState);

  return (
    <StateContext.Provider value={{ state, setState }}>
      {props.children}
    </StateContext.Provider>
  );
};

export const useStore = () => {
  const store = useContext(StateContext);

  if (!store) {
    throw new Error("Couldn't retrieve Store State from Context.");
  }

  const { state, setState } = store;

  return {
    // Selectors

    // Users

    getLoginUsers() {
      return { client: state.clients[0], consultant: state.consultants[0] };
    },

    getUser(userId: string) {
      const users = [...state.clients, ...state.consultants];
      return users.find(c => c.id === userId);
    },

    getClient(userId: string) {
      return state.clients.find(c => c.id === userId);
    },

    getClientOrThrow(userId: string) {
      return assert("client", this.getClient(userId));
    },

    getConsultant(userId: string) {
      return state.consultants.find(c => c.id === userId);
    },

    getConsultantOrThrow(userId: string) {
      return assert("consultant", this.getConsultant(userId));
    },

    getOneTopic() {
      return state.topics[0];
    },

    getTopic(topicId: string) {
      return state.topics.find(t => t.id === topicId);
    },

    getTopicOrThrow(topicId: string) {
      return assert("topic", this.getTopic(topicId));
    },

    getTopicConsultants(topicId: string) {
      const topic = this.getTopic(topicId);

      if (!topic) {
        return [];
      }

      return state.consultants.filter(c =>
        topic.tags.some(tag => c.expertise.includes(tag)),
      );
    },

    getTopicConsultantsCount(topicId: string) {
      // For demo purposes, we're inflating the number of related
      // consultants in this query.
      const OFFSET = 20;

      return this.getTopicConsultants(topicId).length + OFFSET;
    },

    getAllTopics() {
      return state.topics;
    },

    getNTopics(n: number) {
      const topics = state.topics.slice(0, n);

      if (topics.length < n) {
        const placeholder = this.getOneTopic();

        for (let diff = n - topics.length; diff > 0; diff--) {
          topics.push(placeholder);
        }
      }

      return topics;
    },

    getConsultation(consultationId: string) {
      return [
        ...state.draftConsultations,
        ...state.requestedConsultations,
        ...state.matchedConsultations,
        ...state.completeConsultations,
      ].find(c => c.id === consultationId);
    },

    getActiveConsultation(consultationId: string) {
      return state.matchedConsultations.find(c => c.id === consultationId);
    },

    getActiveConsultationOrThrow(consultationId: string) {
      return assert(
        "active consultation",
        this.getActiveConsultation(consultationId),
      );
    },

    getConsultationOrThrow(consultationId: string) {
      return assert("consultation", this.getConsultation(consultationId));
    },

    getDraftConsultation(consultationId: string) {
      return state.draftConsultations.find(c => c.id === consultationId);
    },

    getDraftConsultationOrThrow(consultationId: string) {
      return assert(
        "draft consultation",
        this.getDraftConsultation(consultationId),
      );
    },

    getClientDraftConsultations(clientId: string) {
      return state.draftConsultations.filter(c => c.clientId === clientId);
    },

    getClientMatchingConsultations(clientId: string) {
      return state.requestedConsultations.filter(c => c.clientId === clientId);
    },

    getClientActiveConsultations(clientId: string) {
      return state.matchedConsultations.filter(c => c.clientId === clientId);
    },

    getClientCompleteConsultations(clientId: string) {
      return state.completeConsultations.filter(c => c.clientId === clientId);
    },

    getClientWipConsultations(clientId: string) {
      return [
        ...this.getClientDraftConsultations(clientId),
        ...this.getClientMatchingConsultations(clientId),
        ...this.getClientActiveConsultations(clientId),
      ];
    },

    getConsultantActiveConsultations(consultantId: string) {
      return state.matchedConsultations.filter(
        c => c.consultantId === consultantId,
      );
    },

    getConsultantCompleteConsultations(consultantId: string) {
      return state.completeConsultations.filter(
        c => c.consultantId === consultantId,
      );
    },

    getConsultantWipConsultations(consultantId: string) {
      return this.getConsultantActiveConsultations(consultantId);
    },

    getOpportunities() {
      return state.requestedConsultations;
    },

    // Actions

    createConsultation(
      clientId: string,
      topicId: string,
      consultationId: string,
    ) {
      const topic = this.getTopicOrThrow(topicId);
      const client = this.getClientOrThrow(clientId);
      const newConsultation = creators.createDraftConsultation({
        client,
        topic,
        consultation: { id: consultationId },
      });

      setState(state => {
        return {
          ...state,
          draftConsultations: [newConsultation, ...state.draftConsultations],
        };
      });
    },

    updateDraftConsultationInputValue(
      consultationId: string,
      inputType: "topicInputs" | "companyInputs" | "additionalInputs",
      key: string,
      value: string,
    ) {
      setState(state => {
        return {
          ...state,
          draftConsultations: state.draftConsultations.map(consultation => {
            if (consultation.id === consultationId) {
              return {
                ...consultation,
                [inputType]: consultation[inputType].map(input => {
                  if (input.text === key) {
                    return {
                      ...input,
                      value,
                    };
                  }

                  return input;
                }),
              };
            }

            return consultation;
          }),
        };
      });
    },

    promoteDraftConsultation(consultationId: string) {
      setState(state => {
        const consultation = assert(
          "consultation",
          state.draftConsultations.find(c => c.id === consultationId),
        );

        return {
          ...state,
          requestedConsultations: [
            creators.promoteDraftConsultation(consultation),
            ...state.requestedConsultations,
          ],
          draftConsultations: state.draftConsultations.filter(
            c => c.id !== consultationId,
          ),
        };
      });
    },

    acceptConsultation(consultantId: string, consultationId: string) {
      setState(state => {
        const consultation = assert(
          "consultation",
          state.requestedConsultations.find(c => c.id === consultationId),
        );

        return {
          ...state,
          matchedConsultations: [
            {
              ...consultation,
              state: "MATCHED",
              matchedDate: Date.now(),
              consultantId: consultantId,
            },
            ...state.matchedConsultations,
          ],
          requestedConsultations: state.requestedConsultations.filter(
            c => c.id !== consultationId,
          ),
        };
      });
    },

    rateConsultation(
      consultationId: string,
      eventId: string,
      payload: { rating: number; review: string },
    ) {
      setState(state => {
        return {
          ...state,
          completeConsultations: state.completeConsultations.map(
            consultation => {
              if (consultation.id === consultationId) {
                return {
                  ...consultation,
                  rating: payload.rating,
                  timeline: consultation.timeline.map(item => {
                    if (item.id === eventId) {
                      return { ...item, ...payload };
                    }

                    return item;
                  }),
                };
              }

              return consultation;
            },
          ),
        };
      });
    },

    sendMessage(
      consultationId: string,
      payload: { subject: string; message: string; senderId: string },
    ) {
      setState(
        state => {
          return {
            ...state,
            matchedConsultations: state.matchedConsultations.map(
              consultation => {
                if (consultation.id === consultationId) {
                  const now = Date.now();
                  return {
                    ...consultation,
                    respondedDate:
                      !consultation.respondedDate &&
                      consultation.consultantId === payload.senderId
                        ? now
                        : consultation.respondedDate,
                    timeline: [
                      {
                        ...payload,
                        type: "MESSAGE",
                        id: now.toString(),
                        createdDate: now,
                        consultationId,
                      },
                      ...consultation.timeline,
                    ],
                  };
                }

                return consultation;
              },
            ),
          };
        },
      );
    },
  };
};
