// prettier-ignore
export const path = {
  root: "/",
  login: "/login",

  topicsRoot: "/topics",
  topicDetail: "/topics/:topicId",

  consultationsRoot: "/consultations",
  consultationDetail: "/consultations/:consultationId",

  consultationDraft: "/consultations/:consultationId/draft",
  consultationDraftStep: "/consultations/:consultationId/draft/:step",
  consultationRequested: "/consultations/:consultationId/requested",
  consultationAccepted: "/consultations/:consultationId/accepted",

  consultationTimeline: "/consultations/:consultationId/timeline",
  consultationInfo: "/consultations/:consultationId/info",
  consultationTimelineMatchingStage: "/consultations/:consultationId/timeline/matching",
  consultationTimelineActiveStage: "/consultations/:consultationId/timeline/active",
  consultationTimelineCompleteStage: "/consultations/:consultationId/timeline/complete",
  messageComposer: "/composer/:consultationId",

  opportunitiesRoot: "/opportunities",
  opportunityDetail: "/opportunities/:opId",

  help: "/help",
  notifs: "/notifs",
};
