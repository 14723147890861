import seed from "./seed.json";
import { State } from "./store-manager";
import * as creators from "./creators";
import { assert } from "./utils";

const initialState: State = {
  topics: [],
  clients: [],
  consultants: [],
  draftConsultations: [],
  requestedConsultations: [],
  matchedConsultations: [],
  completeConsultations: [],
};

for (const topic of seed.topics) {
  initialState.topics.push(creators.createTopic({ topic }));
}

for (const user of seed.users) {
  if (user.type === "CLIENT") {
    initialState.clients.push(creators.createClient({ user }));
  }

  if (user.type === "CONSULTANT") {
    initialState.consultants.push(creators.createConsultant({ user }));
  }
}

// prettier-ignore
for (const consultation of seed.consultations) {
  try {
    const client = assert("client", initialState.clients.find(client => client.id === consultation.clientId));
    const topic = assert("topic", initialState.topics.find(topic => topic.id === consultation.topicId));

    if (consultation.state === "DRAFT") {
      initialState.draftConsultations.push(creators.createDraftConsultation({ topic, client, consultation }))
    }
    
    if (consultation.state === "REQUESTED") {
      initialState.requestedConsultations.push(creators.createRequestedConsultation({ topic, client, consultation }))
    }
    
    if (consultation.state === "MATCHED" && consultation.consultantId) {
      initialState.matchedConsultations.push(creators.createMatchedConsultation({ topic, client, consultation }))
    }
    
    if (consultation.state === "COMPLETED" && consultation.consultantId) {
      initialState.completeConsultations.push(creators.createCompletedConsultation({ topic, client, consultation }))
    }  
  } catch (error) {
    console.error("Couldn't initialize consultation:", consultation?.id)
  }
}

export { initialState };
