import day from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

day.extend(relativeTime);

export const fromNow = (date: number) => {
  return day(date).fromNow();
};

export const toNow = (date: number) => {
  return day(date).toNow();
};

export const getRating = (rating?: number) => {
  if (!rating) {
    return "unrated";
  }

  if (rating >= 9) {
    return "promoter";
  }

  if (rating >= 7) {
    return "passive";
  }

  return "detractor";
};
