export const text = {
  long: (function*() {
    while (true) {
      yield "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Diam in arcu cursus euismod. Viverra justo nec ultrices dui sapien eget. Aliquam vestibulum morbi blandit cursus risus. Nunc sed blandit libero volutpat sed. Diam sit amet nisl suscipit adipiscing bibendum. Ullamcorper malesuada proin libero nunc. Aliquam purus sit amet luctus. Semper feugiat nibh sed pulvinar proin. Augue lacus viverra vitae congue eu. Nisl condimentum id venenatis a. Aliquet nibh praesent tristique magna sit. Imperdiet dui accumsan sit amet nulla facilisi morbi tempus iaculis. Vitae proin sagittis nisl rhoncus.";
      yield "Pretium aenean pharetra magna ac. Pulvinar sapien et ligula ullamcorper malesuada proin libero. Consectetur adipiscing elit duis tristique. Euismod in pellentesque massa placerat duis ultricies lacus sed turpis. Fringilla phasellus faucibus scelerisque eleifend donec pretium vulputate sapien. Fusce ut placerat orci nulla pellentesque dignissim enim sit. Mattis rhoncus urna neque viverra. Cursus in hac habitasse platea dictumst quisque sagittis. Aliquam eleifend mi in nulla posuere sollicitudin aliquam. Etiam dignissim diam quis enim. At consectetur lorem donec massa sapien faucibus et. Feugiat sed lectus vestibulum mattis ullamcorper velit sed ullamcorper. Habitasse platea dictumst vestibulum rhoncus est pellentesque elit ullamcorper dignissim. Enim ut sem viverra aliquet eget sit amet tellus cras. Odio ut enim blandit volutpat maecenas volutpat blandit.";
      yield "Auctor neque vitae tempus quam pellentesque nec nam aliquam. Libero justo laoreet sit amet cursus sit amet dictum. Tortor at risus viverra adipiscing at in tellus integer. Faucibus turpis in eu mi bibendum neque. Dapibus ultrices in iaculis nunc sed augue lacus. Feugiat vivamus at augue eget arcu. Tempus urna et pharetra pharetra massa massa ultricies. Eget nullam non nisi est sit amet. Risus sed vulputate odio ut enim blandit. Et odio pellentesque diam volutpat commodo sed egestas. Suscipit tellus mauris a diam maecenas sed. Ultrices sagittis orci a scelerisque. Faucibus interdum posuere lorem ipsum dolor sit. Cras sed felis eget velit aliquet sagittis id. Tellus pellentesque eu tincidunt tortor aliquam nulla facilisi cras fermentum. Sed blandit libero volutpat sed cras ornare arcu dui. Magna fermentum iaculis eu non diam phasellus vestibulum lorem sed. Velit egestas dui id ornare arcu odio. In egestas erat imperdiet sed euismod nisi porta lorem mollis. Varius sit amet mattis vulputate enim nulla aliquet porttitor lacus.";
      yield "Tincidunt dui ut ornare lectus sit amet est placerat in. A diam maecenas sed enim ut. Ultrices sagittis orci a scelerisque purus. Lorem sed risus ultricies tristique nulla aliquet enim tortor at. Fames ac turpis egestas maecenas. Amet nulla facilisi morbi tempus. Vel quam elementum pulvinar etiam non quam lacus suspendisse faucibus. Et malesuada fames ac turpis egestas sed tempus. Tempor orci eu lobortis elementum nibh tellus. Non arcu risus quis varius quam quisque. At imperdiet dui accumsan sit amet. Risus nullam eget felis eget nunc lobortis. Mi tempus imperdiet nulla malesuada pellentesque elit. Id interdum velit laoreet id donec ultrices tincidunt arcu non. Quam nulla porttitor massa id neque aliquam vestibulum morbi. Diam maecenas ultricies mi eget mauris pharetra et ultrices. Volutpat blandit aliquam etiam erat velit scelerisque. Non tellus orci ac auctor augue mauris augue neque gravida. Aliquam id diam maecenas ultricies mi.";
      yield "Aliquam faucibus purus in massa tempor nec. Id volutpat lacus laoreet non curabitur gravida arcu ac. Vel orci porta non pulvinar neque laoreet suspendisse interdum consectetur. A diam maecenas sed enim. Velit euismod in pellentesque massa placerat duis ultricies. Sapien et ligula ullamcorper malesuada proin libero nunc. Mi eget mauris pharetra et ultrices neque ornare aenean euismod. Auctor elit sed vulputate mi sit. Diam maecenas ultricies mi eget mauris pharetra et ultrices neque. Aliquet sagittis id consectetur purus ut faucibus pulvinar elementum. Sit amet massa vitae tortor condimentum lacinia quis vel eros. Pretium quam vulputate dignissim suspendisse in est. Duis convallis convallis tellus id interdum velit laoreet. Ut diam quam nulla porttitor massa id. Ut placerat orci nulla pellentesque dignissim enim sit amet venenatis. Mi eget mauris pharetra et. Posuere urna nec tincidunt praesent semper feugiat. Ac turpis egestas maecenas pharetra convallis posuere morbi leo. Quis eleifend quam adipiscing vitae proin sagittis nisl.";
      yield "Vitae proin sagittis nisl rhoncus. Integer enim neque volutpat ac tincidunt vitae semper quis lectus. Cursus euismod quis viverra nibh cras pulvinar mattis nunc. Sagittis purus sit amet volutpat consequat mauris. Sed elementum tempus egestas sed sed risus. Adipiscing vitae proin sagittis nisl rhoncus mattis rhoncus urna. Vitae aliquet nec ullamcorper sit amet risus nullam eget felis. Massa massa ultricies mi quis hendrerit. Urna condimentum mattis pellentesque id nibh tortor id. Feugiat in fermentum posuere urna nec tincidunt.";
      yield "Viverra aliquet eget sit amet. Egestas erat imperdiet sed euismod. Semper risus in hendrerit gravida rutrum quisque. Turpis egestas maecenas pharetra convallis posuere morbi leo. Lorem ipsum dolor sit amet. Urna nunc id cursus metus aliquam. Dui ut ornare lectus sit amet est placerat in. Ac auctor augue mauris augue neque. Amet aliquam id diam maecenas ultricies mi eget mauris pharetra. Pulvinar pellentesque habitant morbi tristique. Lorem sed risus ultricies tristique nulla. Dignissim suspendisse in est ante in nibh. Phasellus vestibulum lorem sed risus ultricies tristique nulla. Lacinia quis vel eros donec ac odio tempor orci. Tortor condimentum lacinia quis vel eros donec ac odio tempor. Enim lobortis scelerisque fermentum dui faucibus in ornare. Lacinia at quis risus sed vulputate. Mi tempus imperdiet nulla malesuada pellentesque elit eget. Ut sem nulla pharetra diam. At tellus at urna condimentum mattis pellentesque id nibh tortor.";
      yield "Nulla posuere sollicitudin aliquam ultrices sagittis orci a. Risus quis varius quam quisque id diam vel. Metus aliquam eleifend mi in nulla posuere sollicitudin aliquam ultrices. Ipsum nunc aliquet bibendum enim facilisis gravida neque convallis. Phasellus egestas tellus rutrum tellus pellentesque eu. Posuere ac ut consequat semper viverra nam. Et netus et malesuada fames ac turpis egestas sed tempus. Feugiat vivamus at augue eget. Nam libero justo laoreet sit amet cursus sit amet. Eget sit amet tellus cras adipiscing enim eu turpis egestas. Tellus cras adipiscing enim eu turpis egestas. Elementum integer enim neque volutpat ac tincidunt vitae semper quis. Felis eget velit aliquet sagittis. Fusce ut placerat orci nulla pellentesque dignissim enim. Nec feugiat nisl pretium fusce id velit ut tortor pretium. Nunc consequat interdum varius sit amet mattis vulputate. Etiam erat velit scelerisque in dictum non consectetur a erat.";
      yield "Fermentum dui faucibus in ornare quam viverra orci. Bibendum neque egestas congue quisque egestas. Donec et odio pellentesque diam. Odio facilisis mauris sit amet massa vitae. Ut eu sem integer vitae justo eget magna. Vitae tempus quam pellentesque nec nam aliquam. Leo integer malesuada nunc vel risus commodo viverra maecenas accumsan. Egestas tellus rutrum tellus pellentesque eu tincidunt tortor aliquam. Nunc sed id semper risus in hendrerit. Turpis in eu mi bibendum neque. Maecenas ultricies mi eget mauris pharetra. Nulla porttitor massa id neque aliquam vestibulum morbi blandit. Ultrices sagittis orci a scelerisque.";
      yield "Semper quis lectus nulla at volutpat. A cras semper auctor neque. Et ultrices neque ornare aenean euismod elementum nisi quis eleifend. Viverra suspendisse potenti nullam ac. Laoreet suspendisse interdum consectetur libero id faucibus. Ultricies lacus sed turpis tincidunt id aliquet. Velit sed ullamcorper morbi tincidunt ornare massa eget egestas purus. Porttitor massa id neque aliquam vestibulum morbi. Nibh praesent tristique magna sit amet purus gravida quis blandit. Phasellus faucibus scelerisque eleifend donec pretium vulputate sapien. Amet est placerat in egestas erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames. Pharetra pharetra massa massa ultricies mi quis hendrerit dolor. Justo donec enim diam vulputate ut pharetra sit amet. Faucibus ornare suspendisse sed nisi lacus sed viverra tellus in. Adipiscing enim eu turpis egestas pretium aenean. Fermentum et sollicitudin ac orci.";
      yield "Lectus quam id leo in. Nulla pellentesque dignissim enim sit amet venenatis urna cursus eget. Platea dictumst vestibulum rhoncus est pellentesque. Ipsum dolor sit amet consectetur adipiscing elit ut. Arcu dui vivamus arcu felis. Bibendum at varius vel pharetra vel turpis. Egestas quis ipsum suspendisse ultrices gravida. Urna et pharetra pharetra massa. Vulputate enim nulla aliquet porttitor. Amet consectetur adipiscing elit pellentesque habitant morbi. A condimentum vitae sapien pellentesque. Sagittis id consectetur purus ut faucibus. Vel eros donec ac odio tempor orci. Aliquam nulla facilisi cras fermentum odio eu. Iaculis nunc sed augue lacus viverra vitae congue.";
      yield "Suspendisse faucibus interdum posuere lorem ipsum dolor. Aenean euismod elementum nisi quis eleifend quam adipiscing. Donec et odio pellentesque diam volutpat. Neque viverra justo nec ultrices. Mauris rhoncus aenean vel elit scelerisque mauris pellentesque pulvinar pellentesque. Sit amet mauris commodo quis. Turpis nunc eget lorem dolor. At tellus at urna condimentum mattis pellentesque id nibh tortor. Imperdiet dui accumsan sit amet nulla facilisi morbi. Viverra adipiscing at in tellus integer feugiat. Eget duis at tellus at urna condimentum mattis pellentesque.";
      yield "Purus gravida quis blandit turpis cursus in hac habitasse platea. Sollicitudin tempor id eu nisl. Amet purus gravida quis blandit. In nisl nisi scelerisque eu. Diam ut venenatis tellus in metus vulputate eu scelerisque felis. Massa tincidunt nunc pulvinar sapien et ligula ullamcorper malesuada. Dolor sit amet consectetur adipiscing elit pellentesque. Est lorem ipsum dolor sit amet consectetur adipiscing elit pellentesque. Ut faucibus pulvinar elementum integer enim neque volutpat. Mollis aliquam ut porttitor leo a diam sollicitudin.";
      yield "Vestibulum sed arcu non odio euismod lacinia at. Tincidunt praesent semper feugiat nibh sed pulvinar. Risus at ultrices mi tempus imperdiet nulla malesuada pellentesque. Nec nam aliquam sem et tortor consequat id porta nibh. Congue nisi vitae suscipit tellus mauris a diam. Eget egestas purus viverra accumsan. Velit laoreet id donec ultrices. Gravida arcu ac tortor dignissim convallis aenean. Pharetra sit amet aliquam id diam maecenas ultricies. Ultrices sagittis orci a scelerisque purus semper. Imperdiet dui accumsan sit amet. Nulla aliquet porttitor lacus luctus accumsan tortor.";
      yield "Vestibulum mattis ullamcorper velit sed. Commodo ullamcorper a lacus vestibulum sed arcu non. Tincidunt tortor aliquam nulla facilisi. Velit aliquet sagittis id consectetur purus ut faucibus. Amet risus nullam eget felis. Diam volutpat commodo sed egestas egestas fringilla phasellus faucibus. Turpis cursus in hac habitasse platea. Quisque non tellus orci ac. Augue lacus viverra vitae congue eu consequat ac. Rhoncus dolor purus non enim praesent elementum facilisis. Consectetur libero id faucibus nisl tincidunt eget nullam non. Tellus elementum sagittis vitae et leo duis ut diam quam. Mi sit amet mauris commodo quis.";
      yield "Volutpat odio facilisis mauris sit. Integer eget aliquet nibh praesent tristique magna sit amet. Purus viverra accumsan in nisl nisi scelerisque eu ultrices. Id donec ultrices tincidunt arcu non sodales neque. Venenatis urna cursus eget nunc scelerisque viverra mauris in. Mollis nunc sed id semper risus in hendrerit gravida rutrum. Egestas purus viverra accumsan in nisl nisi. Fermentum odio eu feugiat pretium nibh ipsum consequat. Sapien eget mi proin sed libero enim sed faucibus turpis. Morbi tempus iaculis urna id volutpat. Sed viverra tellus in hac habitasse platea. Etiam tempor orci eu lobortis elementum nibh tellus molestie nunc. Justo eget magna fermentum iaculis eu non diam. Amet venenatis urna cursus eget nunc scelerisque viverra.";
      yield "Blandit turpis cursus in hac habitasse platea. Faucibus et molestie ac feugiat. Odio pellentesque diam volutpat commodo sed egestas egestas fringilla. Tempor commodo ullamcorper a lacus vestibulum sed arcu non. Turpis massa tincidunt dui ut. In metus vulputate eu scelerisque felis imperdiet proin. Id aliquet risus feugiat in ante metus. Sed odio morbi quis commodo odio aenean. Eu feugiat pretium nibh ipsum. Augue ut lectus arcu bibendum at varius. Tristique senectus et netus et malesuada fames ac turpis egestas. Rhoncus dolor purus non enim praesent elementum facilisis leo. Venenatis urna cursus eget nunc scelerisque viverra mauris in aliquam. Ac turpis egestas sed tempus urna.";
    }
  })(),
};

export const number = {
  small: (function*() {
    while (true) {
      yield 10;
      yield 3;
      yield 5;
      yield 8;
      yield 2;
      yield 4;
      yield 5;
      yield 7;
      yield 2;
    }
  })(),
  large: (function*() {
    while (true) {
      yield 80;
      yield 62;
      yield 128;
      yield 76;
      yield 59;
    }
  })(),
  huge: (function*() {
    while (true) {
      yield 1203;
      yield 670;
      yield 849;
      yield 964;
      yield 2953;
    }
  })(),
};

export const month = (function*() {
  while (true) {
    yield "January";
    yield "March";
    yield "June";
    yield "September";
  }
})();

export const id = (function*() {
  let index = 0;
  while (true) {
    yield index.toString();
    index = index + 1;
  }
})();
