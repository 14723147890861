import React, { Fragment } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { Portrait } from "./portrait";
import { useHover } from "./hooks";
import { color } from "./stylesheet";
import { path } from "./path";
import { pick } from "./utils";
import { useCurrentUser } from "./user-view";

const text = {
  topics: "Topics",
  consultations: "Consultations",
  opportunities: "Opportunities",
  help: "Help",
};

const ITEM_SPACING = "0.75rem";

type TopNavLinkProps = {
  label: string;
  href: string;
};

const TopNavLink = (props: TopNavLinkProps) => {
  const { hovered, handlers } = useHover();
  const match = useRouteMatch({ path: props.href });

  return (
    <Link
      {...handlers}
      key={props.href}
      to={props.href}
      style={{
        padding: ITEM_SPACING,
        fontSize: "1.125rem",
        color: match ? color.RED : color.FADED_BLACK,
        backgroundColor: hovered ? color.VERY_LIGHT_GRAY : "transparent",
        borderRadius: "1rem",
      }}>
      {props.label}
    </Link>
  );
};

export const TopNav = () => {
  const user = useCurrentUser();

  return (
    <div
      style={{
        display: "flex",
        height: "5.5rem",
        alignItems: "center",
        position: "sticky",
        zIndex: 10,
        top: 0,
        boxShadow: `0 1px 0 0 ${color.LIGHT_GRAY}`,
        backgroundColor: "white",
      }}>
      <Link
        to={path.root}
        style={{ display: "block", marginLeft: "2rem", marginRight: "auto" }}>
        <img
          src={pick(user.type, {
            CLIENT: "/images/logo-clients.svg",
            CONSULTANT: "/images/logo-consultants.svg",
          })}
          alt="Guild"
          style={{ display: "block" }}
        />
      </Link>
      {user.type === "CLIENT" && (
        <Fragment>
          <TopNavLink label={text.topics} href={path.topicsRoot} />
          <TopNavLink
            label={text.consultations}
            href={path.consultationsRoot}
          />
        </Fragment>
      )}
      {user.type === "CONSULTANT" && (
        <Fragment>
          <TopNavLink
            label={text.opportunities}
            href={path.opportunitiesRoot}
          />
          <TopNavLink
            label={text.consultations}
            href={path.consultationsRoot}
          />
        </Fragment>
      )}

      <TopNavLink label={text.help} href={path.help} />

      <Link to={path.notifs}>
        <img
          src="/icons/inbox.svg"
          alt="Notifications"
          style={{ padding: ITEM_SPACING, display: "block" }}
        />
      </Link>
      <Link to={path.login}>
        <Portrait
          userId={user.id}
          size="2.625rem"
          marginLeft={ITEM_SPACING}
          marginRight="1.5rem"
        />
      </Link>
    </div>
  );
};
