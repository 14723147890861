import React, { ReactNode } from "react";

type SidecarProps = {
  children: ReactNode;
};

export const Sidecar = (props: SidecarProps) => {
  const [first, ...rest] = React.Children.toArray(props.children);

  return (
    <div
      style={{
        position: "sticky",
        top: "7rem",
        backgroundColor: "#3F3F3F",
      }}>
      <div
        style={{
          backgroundColor: "#2D2D2D",
          padding: "2rem",
        }}>
        {first}
      </div>
      <div style={{ padding: "2rem" }}>{rest}</div>
    </div>
  );
};
