import { css } from "@emotion/css";
import { color } from "./stylesheet";
import { pick } from "./utils";

type HtmlTextProps = {
  children: string;
  color?: string;
  fontSize?: "tiny" | "small" | "medium";
  marginTop?: string;
  marginBottom?: string;
};

export const HtmlText = (props: HtmlTextProps) => {
  const size = props.fontSize || "medium";

  const fontSize = pick(size, {
    tiny: "0.875rem",
    small: "1rem",
    medium: "1.125rem",
  });

  const verticalRythm = pick(size, {
    tiny: "1.125rem",
    small: "1.25rem",
    medium: "1.5rem",
  });

  return (
    <div
      dangerouslySetInnerHTML={{ __html: props.children }}
      className={css({
        fontSize,
        color: props.color || color.DARK_GRAY,
        lineHeight: verticalRythm,
        marginBottom: props.marginBottom,
        marginTop: props.marginTop,
        strong: {
          color: color.FADED_BLACK,
        },
        a: {
          textDecoration: "underline",
        },
        "a:hover": {
          color: color.BLUE,
          textDecoration: "underline",
        },
        "& > *": {
          marginBottom: verticalRythm,
        },
        "& > *:last-child": {
          marginBottom: "0",
        },
      })}
    />
  );
};
