export const color = {
  FADED_BLACK: "#353535",
  DARK_GRAY: "#616161",
  LIGHT_GRAY: "#E5EAE9",
  VERY_LIGHT_GRAY: "#F7F7F7",
  ORANGE: "#FEB54F",
  RED: "#FF3F3A",
  DARK_RED: "#E12723",
  BLUE: "#4F9BFE",
  DARK_BLUE: "#347FE0",
};

export const size = {
  MAX_WIDTH: "70rem",
  BODY_PADDING: "2rem",
  MEDIUM_HEADING_SPACING: "2.5rem",
  SMALL_HEADING_SPACING: "1.125rem",
};

export const shadow = {
  INSET: `inset 0 0 0 1px ${color.LIGHT_GRAY}`,
};
