import React, { ReactNode } from "react";
import { color } from "./stylesheet";

type LargeHeadingProps = {
  children: ReactNode;
  id?: string;
  marginBottom?: string;
};

export const LargeHeading = (props: LargeHeadingProps) => {
  return (
    <div
      id={props.id}
      style={{
        fontSize: "2.625rem",
        fontWeight: 700,
        color: color.FADED_BLACK,
        marginBottom: props.marginBottom,
      }}>
      {props.children}
    </div>
  );
};

type MediumHeadingProps = {
  children: ReactNode;
  id?: string;
  color?: string;
  marginBottom?: string;
};

export const MediumHeading = (props: MediumHeadingProps) => {
  return (
    <div
      id={props.id}
      style={{
        fontSize: "2rem",
        fontWeight: 700,
        lineHeight: "2.25rem",
        color: props.color || color.FADED_BLACK,
        marginBottom: props.marginBottom,
      }}>
      {props.children}
    </div>
  );
};

type SmallHeadingProps = {
  children: ReactNode;
  id?: string;
  color?: string;
  marginTop?: string;
  marginBottom?: string;
};

export const SmallHeading = (props: SmallHeadingProps) => {
  return (
    <div
      id={props.id}
      style={{
        fontSize: "1.375rem",
        fontWeight: 700,
        lineHeight: "1.5rem",
        color: props.color || color.FADED_BLACK,
        marginTop: props.marginTop,
        marginBottom: props.marginBottom,
      }}>
      {props.children}
    </div>
  );
};

type SubheadingProps = {
  children: ReactNode;
  id?: string;
  marginTop?: string;
  marginBottom?: string;
};

export const Subheading = (props: SubheadingProps) => {
  return (
    <div
    id={props.id}
      style={{
        textTransform: "uppercase",
        fontWeight: 700,
        color: color.DARK_GRAY,
        opacity: 0.6,
        marginTop: props.marginTop,
        marginBottom: props.marginBottom,
      }}>
      {props.children}
    </div>
  );
};
