import React, { Fragment } from "react";
import { MediumHeading } from "./headings";
import { HtmlText } from "./html-text";
import { color, size } from "./stylesheet";

type LegendItemProps = {
  color: string;
  text: string;
};

const LegendItem = (props: LegendItemProps) => {
  return (
    <Fragment>
      <div
        style={{
          height: "1.5rem",
          backgroundColor: props.color,
        }}
      />
      <div style={{ fontSize: "0.875rem", color: "#616161" }}>{props.text}</div>
    </Fragment>
  );
};

type TimelineSectionProps = {
  heading: string;
  text: string;
  stages: {
    days: number;
    color: string;
    text: string;
  }[];
};

export const TimelineSection = (props: TimelineSectionProps) => {
  const days = props.stages.flatMap(stage =>
    Array.from({ length: stage.days }).fill({ color: stage.color }),
  ) as { color: string }[];

  return (
    <Fragment>
      <MediumHeading id="timeline" marginBottom={size.MEDIUM_HEADING_SPACING}>
        {props.heading}
      </MediumHeading>
      {props.text !== "" && <HtmlText>{props.text}</HtmlText>}
      <div>
        <div
          style={{
            display: "grid",
            gridTemplate: "auto / repeat(7, 1fr)",
            gridGap: "2px",
            margin: "2rem 0",
          }}>
          {days.map((day, i) => {
            return (
              <div
                key={i}
                style={{
                  color: color.DARK_GRAY,
                  backgroundColor: day.color,
                  padding: "0.5rem",
                  lineHeight: 1,
                  fontWeight: 700,
                }}>
                Day {i + 1}
              </div>
            );
          })}
        </div>
        <div
          style={{
            display: "grid",
            gridTemplate: "auto / 1.5rem 1fr",
            gridGap: "1rem",
            alignItems: "center",
          }}>
          {props.stages.map(stage => {
            return (
              <LegendItem
                key={stage.text}
                color={stage.color}
                text={stage.text}
              />
            );
          })}
        </div>
      </div>
    </Fragment>
  );
};
