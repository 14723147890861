import React from "react";
import { when } from "./utils";
import { color } from "./stylesheet";

type IconCircleProps = {
  icon: string;
  backgroundColor?: string;
  size?: string;
  border?: boolean;
  display?: "flex" | "inline-flex";
};

export const IconCircle = (props: IconCircleProps) => {
  return (
    <div
      style={{
        display: props.display || "flex",
        alignItems: "center",
        justifyContent: "center",
        width: props.size || "3rem",
        height: props.size || "3rem",
        borderRadius: "50%",
        backgroundColor: props.backgroundColor || "rgba(255,255,255,0.2)",
        boxShadow: when(props.border, `0 0 0 1px ${color.LIGHT_GRAY}`),
      }}>
      <img src={`/icons/${props.icon}.svg`} alt="" />
    </div>
  );
};
