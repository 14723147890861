/* eslint-disable no-fallthrough */
import * as types from "./types";
import { template, pick, when } from "./utils";
import { number, month, id, text } from "./generators";

const ONE_DAY_MS = 86400000;

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

// prettier-ignore
export const promoteDraftConsultation = (consultation: types.DraftConsultation): types.RequestedConsultation => {
const now = Date.now();
  return {
    ...consultation,
    state: "REQUESTED",
    requestedDate: now,
    timeline: [
      {
        id: now.toString(),
        type: "REQUESTED",
        consultationId: consultation.id,
        createdDate: now,
        consultationInputs: serializeConsultationInputs(consultation),
      },
    ],
  };
}

const createAdditionalInputs = (): types.ConsultationInput[] => {
  return [
    {
      type: "text",
      text: "Anything else you’d like to ask you consultant?",
      value: "",
    },
    {
      type: "files",
      text: "Supplemental documents",
      hint:
        "If you want to attach additional supporting documents (eg. PDFs, word documents, etc.), you can add them here.",
      value: "",
    },
  ];
};

type ComposeConsultationDataPayload = {
  client: types.User;
  topic: types.Topic;
  fillValues?: boolean;
};

const composeConsultationData = (payload: ComposeConsultationDataPayload) => {
  return {
    name: payload.topic.name,
    price: payload.topic.price,
    consultantFee: payload.topic.consultantFee,
    tags: payload.topic.tags,
    topicId: payload.topic.id,
    outputs: payload.topic.outputs,
    topicInputs: payload.topic.topicInputs.map(input => {
      return {
        ...input,
        value: payload.fillValues
          ? input.mockText || text.long.next().value
          : "",
      };
    }),
    companyInputs: payload.topic.companyInputs.map(input => {
      return {
        ...input,
        value: payload.fillValues
          ? input.mockText || text.long.next().value
          : "",
      };
    }),
    additionalInputs: createAdditionalInputs(),
    clientId: payload.client.id,
  };
};

type CreateDraftConsultationPayload = {
  client: types.User;
  topic: types.Topic;
  consultation: { id: string };
};

// prettier-ignore
export const createDraftConsultation = (payload: CreateDraftConsultationPayload): types.DraftConsultation => {
  const createdDate = Date.now();

  return {
    ...composeConsultationData(payload),
    id: payload.consultation.id,
    state: "DRAFT",
    createdDate,
    timeline: [],
  };
};

export function serializeConsultationInputs(consultation: types.Consultation) {
  return serializeInputs([
    ...consultation.topicInputs,
    ...consultation.companyInputs,
    ...consultation.additionalInputs,
  ]);
}

export function serializeInputs(inputs: { text: string; value: string }[]) {
  return inputs.reduce((acc, item) => {
    if (!item.value) {
      return acc;
    }

    const question = `<p><strong>${item.text}</strong></p>`;
    const response = item.value
      .split(/\n+/)
      .map(line => `<p>${line}</p>`)
      .join("");

    return acc + question + response;
  }, "");
}

//prettier-ignore
const createTimelineItems = (consultation: types.Consultation): types.TimelineItem[] => {
  const timeline: types.TimelineItem[] = [];

  switch (consultation.state) {
    case "COMPLETED":
      timeline.push({
        id: id.next().value,
        type: "RATE_CONSULTANT",
        consultationId: consultation.id,
        createdDate: consultation.completedDate,
        rating: consultation.rating,
        review: when(!!consultation.rating, "<p>Highly recommend!</p>"),
      });
    case "MATCHED":
      
      if (consultation.respondedDate) {
        timeline.push({
          type: "MESSAGE",
          id: id.next().value,
          consultationId: consultation.id,
          createdDate: consultation.respondedDate,
          senderId: consultation.clientId,
          subject: "Re: Consultation",
          message: `
            <p>Hi John,<br/><br/>
            Yes, that is corrent. We are currently planning nulla at volutpat diam ut. Blandit aliquam etiam erat velit scelerisque. Odio morbi quis commodo.<br/><br/>
            Maria</p>`,
        });
        timeline.push({
          type: "MESSAGE",
          id: id.next().value,
          consultationId: consultation.id,
          createdDate: consultation.respondedDate,
          senderId: consultation.consultantId,
          subject: "Consultation",
          attachemnts: [{ size: "2 MB", name: "Integrations Breakdown Diagram.pdf"}],
          message: `
            <p>Hi Maria,<br/><br/>
            I just wanted to double check with you your response to “Odio morbi quis commodo”. Oio aenean sed adipiscing diam donec. Enim nulla aliquet porttitor lacus luctus<br/><br/>         
            I am still a bit unclear about consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua?<br/><br/>          
            All the best,<br/>
            John</p>`,
        });
      }

      timeline.push({
        type: "MATCH",
        id: id.next().value,
        consultationId: consultation.id,
        consultantId: consultation.consultantId,
        createdDate: consultation.requestedDate,
      });
    case "REQUESTED":
      timeline.push({
        type: "REQUESTED",
        id: id.next().value,
        consultationId: consultation.id,
        createdDate: consultation.requestedDate,
        consultationInputs: serializeConsultationInputs(consultation),
      });
  }

  return timeline;
};

type CreateRequestedConsultationPayload = {
  client: types.User;
  topic: types.Topic;
  consultation: { id: string; consultantId?: string };
};

// prettier-ignore
export const createRequestedConsultation = (payload: CreateRequestedConsultationPayload): types.RequestedConsultation => {
  const createdDate = Date.now();
  const requestedDate = createdDate + ONE_DAY_MS;

  const consultation: types.RequestedConsultation = {
    ...composeConsultationData({...payload, fillValues: true }),
    ...payload.consultation,
    state: "REQUESTED",
    createdDate,
    requestedDate,
    timeline: [],
  };

  consultation.timeline = createTimelineItems(consultation);

  return consultation;
};

type CreateMatchedConsultationPayload = {
  client: types.User;
  topic: types.Topic;
  consultation: {
    id: string;
    consultantId: string;
  };
};

// prettier-ignore
export const createMatchedConsultation = (payload: CreateMatchedConsultationPayload): types.MatchedConsultation => {
  const createdDate = Date.now();
  const requestedDate = createdDate + ONE_DAY_MS;
  const matchedDate = requestedDate + ONE_DAY_MS;
  const respondedDate = matchedDate + ONE_DAY_MS;

  const consultation: types.MatchedConsultation = {
    ...composeConsultationData({...payload, fillValues: true }),
    ...payload.consultation,
    state: "MATCHED",
    createdDate,
    requestedDate,
    matchedDate,
    respondedDate,
    timeline: [],
  };

  consultation.timeline = createTimelineItems(consultation);

  return consultation;
};

type CreateCompletedConsultationPayload = {
  client: types.User;
  topic: types.Topic;
  consultation: {
    id: string;
    consultantId: string;
    rating?: number | null;
  };
};

// prettier-ignore

export const createCompletedConsultation = (payload: CreateCompletedConsultationPayload): types.CompleteConsultation => {
  const createdDate = Date.now();
  const requestedDate = createdDate + ONE_DAY_MS;
  const matchedDate = requestedDate + ONE_DAY_MS;
  const respondedDate = requestedDate + ONE_DAY_MS;
  const completedDate = matchedDate + ONE_DAY_MS;

  const consultation: types.CompleteConsultation = {
    ...composeConsultationData({...payload, fillValues: true }),
    ...payload.consultation,
    state: "COMPLETED",
    createdDate,
    requestedDate,
    matchedDate,
    completedDate,
    respondedDate,
    rating: payload.consultation.rating === null ? undefined : payload.consultation.rating,
    timeline: [],
  };

  consultation.timeline = createTimelineItems(consultation);

  return consultation;
};

type CreateUserHighlightsPayload = {
  name: string;
  type: "CLIENT" | "CONSULTANT";
};

// prettier-ignore
const createUserHighlights = (payload: CreateUserHighlightsPayload) => {
  return [
    {
      icon: "diamond",
      text: template(
        pick(payload.type, {
          CLIENT: "{COUNT} consultants highly recommend working with {NAME}",
          CONSULTANT: "{COUNT} clients marked {NAME}’s consultations as excellent"
        }),
        { COUNT: number.small.next().value, NAME: payload.name },
      ),
    },
    {
      icon: "briefcase",
      text: template(
        pick(payload.type, {
          CLIENT: "{COUNT} consultations comissioned",
          CONSULTANT: "{COUNT} consultations completed",
        }),
        { COUNT: number.large.next().value },
      ),
    },
    {
      icon: "rocket",
      text: template(
        "Guild member since {MONTH}",
        { MONTH: month.next().value }
      ),
    },
  ];
};

type PartialUser = Omit<
  PartialBy<types.User, "bio" | "rating" | "expertise" | "highlights">,
  "type"
>;

type CreateClientPayload = {
  user: PartialUser;
};

// prettier-ignore
export const createClient = (payload: CreateClientPayload): types.Client => {
  const userType = "CLIENT";

  return {
    id: payload.user.id,
    name: payload.user.name,
    portrait: payload.user.portrait,
    type: userType,
    bio: payload.user.bio || template(
      "{NAME} has {YEARS} years experience in the field of tistique senectus et netus et malesuada fames. Facilisi nullam vehicula ipsum a arcu cursus. Quisque id diam vel quam elementum pulvinar etiam non quam. Tincidunt nunc pulvinar sapien et ligula. Eget felis eget nunc lobortis mattis aliquam.",
      { NAME: payload.user.name, YEARS: number.small.next().value },
    ),
    rating: payload.user.rating || 8,
    expertise: payload.user.expertise || [],
    highlights: payload.user.highlights || createUserHighlights({ name: payload.user.name, type: userType }),
  };
};

type CreateConsultantPayload = {
  user: PartialUser;
};

// prettier-ignore
export const createConsultant = (payload: CreateConsultantPayload): types.Consultant => {
  const userType = "CONSULTANT";

  return {
    ...payload.user,
    type: userType,
    bio: payload.user.bio || template(
      "{NAME} has {YEARS} years experience in the field of tistique senectus et netus et malesuada fames. Facilisi nullam vehicula ipsum a arcu cursus. Quisque id diam vel quam elementum pulvinar etiam non quam. Tincidunt nunc pulvinar sapien et ligula. Eget felis eget nunc lobortis mattis aliquam.",
      { NAME: payload.user.name, YEARS: number.small.next().value },
    ),
    rating: payload.user.rating || 8,
    expertise: payload.user.expertise || [],
    highlights: payload.user.highlights || createUserHighlights({ name: payload.user.name, type: userType }),
  };
};

const createTopicHighlights = () => {
  const consultationsCount = number.huge.next().value;
  const excellentCount = consultationsCount - number.large.next().value;
  const returnedCount = excellentCount - number.small.next().value;

  return [
    {
      icon: "briefcase",
      text: template(
        "<b>{COUNT} clients</b> got a consultation about this topic",
        { COUNT: consultationsCount },
      ),
    },
    {
      icon: "diamond",
      text: template(
        "<b>{COUNT} clients</b> rated the consultation they received as ‘excellent’",
        { COUNT: excellentCount },
      ),
    },
    {
      icon: "sync",
      text: template(
        "<b>{COUNT} clients</b> returned to Guild for help with other topics.",
        { COUNT: returnedCount },
      ),
    },
  ];
};

// prettier-ignore
const createTopicInputs = (inputs: { text: string; type: string, mockText?: string }[]) => {
  const returnValue: types.TopicInput[] = [];

  for (const input of inputs) {
    // Needed for TypeScript to cast strings to string literal enumeration.
    const inputType =
      input.type === "files" ? "files" :
      input.type === "text-short" ? "text-short" :
      "text";

    returnValue.push({ ...input, type: inputType });
  }

  return returnValue;
};

type CreateTopicPayload = {
  topic: Omit<
    PartialBy<types.Topic, "shortDescription" | "highlights">,
    "topicInputs" | "companyInputs" | "additionalInputs"
  > & {
    topicInputs: { text: string; type: string }[];
    companyInputs: { text: string; type: string }[];
  };
};

//prettier-ignore
export const createTopic = (payload: CreateTopicPayload): types.Topic => {
  return {
    ...payload.topic,
    topicInputs: createTopicInputs(payload.topic.topicInputs),
    companyInputs: createTopicInputs(payload.topic.companyInputs),
    shortDescription: payload.topic.shortDescription || payload.topic.description.slice(0, 120),
    highlights: payload.topic.highlights || createTopicHighlights(),
  };
};
