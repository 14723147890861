import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import { useHover } from "./hooks";
import { color } from "./stylesheet";
import { pick, when } from "./utils";

type ButtonTheme = "red" | "white" | "blue";

type buttonStyleProps = {
  theme?: ButtonTheme;
  hovered?: boolean;
  display?: "block" | "inline-block";
};

const buttonStyle = (props: buttonStyleProps): React.CSSProperties => {
  const theme = props.theme || "red";

  return {
    display: props.display || "inline-block",
    minWidth: "6rem",
    width: when(props.display === "block", "100%"),
    color: pick(theme, {
      red: "white",
      blue: "white",
      white: color.DARK_GRAY,
    }),
    backgroundColor: pick(theme, {
      red: props.hovered ? color.DARK_RED : color.RED,
      blue: props.hovered ? color.DARK_BLUE : color.BLUE,
      white: props.hovered ? color.VERY_LIGHT_GRAY : "white",
    }),
    boxShadow: when(theme === "white", `inset 0 0 0 1px #c8c8c8`),
    padding: "0.875rem 1.25rem",
    textAlign: "center",
    fontSize: "1.125rem",
    fontWeight: 700,
    cursor: "pointer",
    boxSizing: "border-box",
  };
};

type LinkButtonProps = {
  to: string;
  children: ReactNode;
  theme?: ButtonTheme;
  display?: "block" | "inline-block";
};

export const LinkButton = (props: LinkButtonProps) => {
  const { hovered, handlers } = useHover();

  return (
    <Link
      {...handlers}
      to={props.to}
      style={buttonStyle({
        theme: props.theme,
        display: props.display,
        hovered,
      })}>
      {props.children}
    </Link>
  );
};

type ButtonProps = {
  children: ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  theme?: ButtonTheme;
  display?: "block" | "inline-block";
};

export const Button = (props: ButtonProps) => {
  const { hovered, handlers } = useHover();

  return (
    <button
      {...handlers}
      type="button"
      onClick={props.onClick}
      style={{
        ...buttonStyle({ theme: props.theme, display: props.display, hovered }),
        border: "none",
      }}>
      {props.children}
    </button>
  );
};
