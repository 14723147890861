import React, { ReactNode } from "react";
import { size } from "./stylesheet";

type ContainerProps = {
  id?: string;
  children: ReactNode;
  sidebar?: ReactNode;
  marginTop?: string;
};

export const Container = (props: ContainerProps) => {
  return (
    <div
      id={props.id}
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(10, 1fr)",
        maxWidth: size.MAX_WIDTH,
        margin: "0 auto",
        padding: `${props.marginTop || "0"} ${size.BODY_PADDING} 12rem`,
      }}>
      <div style={{ gridColumn: "span 6" }}>{props.children}</div>
      {props.sidebar && (
        <div style={{ gridColumn: "8 / span 3" }}>{props.sidebar}</div>
      )}
    </div>
  );
};
