import React, { ReactNode, Fragment } from "react";
import {
  Redirect,
  Switch,
  Route,
  useRouteMatch,
  Link,
  generatePath,
} from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import {
  ConsultationHeader,
  ConsultationHeaderLink,
} from "./consultation-header";
import { color, size, shadow } from "./stylesheet";
import { NotInPrototypeView } from "./info-views";
import { IconCircle } from "./icon-circle";
import { path } from "./path";
import { pick, template } from "./utils";
import { TimelineView, TimelineBanner } from "./timeline-view";
import { useStore } from "./store-manager";
import { useConsultation } from "./consultation-detail-view";
import { useCurrentUser } from "./user-view";
import { toNow } from "./formatters";
import dayjs from "dayjs";
import { Consultation } from "./types";
import { useHover } from "./hooks";

export const getStatusText = (payload: { consultation: Consultation }) => {
  switch (payload.consultation.state) {
    case "MATCHED":
      if (payload.consultation.respondedDate) {
        const followupDeadlineDate = dayjs(payload.consultation.respondedDate)
          .add(3, "day")
          .fromNow();

        return template("Consultation ends {DATE}", {
          DATE: followupDeadlineDate,
        });
      }

      const responseDeadlineDate = dayjs(payload.consultation.matchedDate)
        .add(3, "day")
        .fromNow();

      return template("Response due {DATE}", {
        DATE: responseDeadlineDate,
      });

    case "REQUESTED":
      return "Looking for a consultant";
  }
};

type ConsultationTimelineStageProps = {
  label: string;
  href: string;
  status: "COMPLETED" | "XXX" | "UPCOMING";
  timeline?: ({
    id: string;
    label: string;
    date: string;
    icon?: string;
    portrait?: string;
  } | null)[];
};

const ConsultationTimelineStage = (props: ConsultationTimelineStageProps) => {
  const match = useRouteMatch({ path: props.href });
  const timeline = props.timeline?.filter(Boolean);

  return (
    <div
      style={{
        boxShadow: `0 1px 0 0 ${color.LIGHT_GRAY}, 0 -1px 0 0 ${color.LIGHT_GRAY}`,
        backgroundColor: "white",
      }}>
      <Link
        to={props.href}
        style={{
          fontSize: "1.125rem",
          lineHeight: "1.25rem",
          fontWeight: 700,
          color: color.FADED_BLACK,
          padding: "1.5rem 0",
          display: "flex",
          alignContent: "center",
        }}>
        {props.status !== "UPCOMING" && (
          <img
            src={`/icons/${pick(props.status, {
              COMPLETED: "checkmark",
              XXX: "caret-right",
            })}.svg`}
            alt=""
            style={{ marginRight: "1rem" }}
          />
        )}
        <div style={{ opacity: match ? 1 : 0.5 }}> {props.label}</div>
      </Link>
      {match && timeline && timeline.length > 0 && (
        <div
          style={{
            position: "relative",
            paddingBottom: "2rem",
            display: "grid",
            gridGap: "1rem",
          }}>
          <div
            style={{
              position: "absolute",
              top: "1rem",
              bottom: "2rem",
              left: "1.125rem",
              width: "0.125rem",
              backgroundColor: "#E4E4E4",
              opacity: 0.5,
            }}
          />
          {timeline.map(item => {
            if (!item) {
              return null;
            }

            return (
              <ScrollLink
                key={item.id}
                to={item.id}
                offset={-120}
                smooth
                style={{
                  display: "grid",
                  gridTemplateColumns: "2.5rem 1fr",
                  gridColumnGap: "1rem",
                  alignItems: "center",
                  position: "relative",
                  cursor: "pointer",
                }}>
                {(item.portrait || item.icon) && item.icon ? (
                  <IconCircle
                    backgroundColor="#E4E4E4"
                    icon={item.icon}
                    size="2.5rem"
                  />
                ) : (
                  <img
                    src={item.portrait}
                    alt=""
                    style={{
                      width: "2.5rem",
                      height: "2.5rem",
                      borderRadius: "50%",
                    }}
                  />
                )}
                <div>
                  <div
                    style={{
                      fontSize: "1.125rem",
                      lineHeight: "1.125rem",
                      color: color.FADED_BLACK,
                    }}>
                    {item.label}
                  </div>
                  <div
                    style={{
                      marginTop: "0.125rem",
                      fontSize: "1rem",
                      lineHeight: "1rem",
                      color: color.DARK_GRAY,
                      opacity: 0.5,
                    }}>
                    {item.date}
                  </div>
                </div>
              </ScrollLink>
            );
          })}
        </div>
      )}
    </div>
  );
};

type ConsultationTimelineNavProps = {
  children: ReactNode;
};

const ConsultationTimelineNav = (props: ConsultationTimelineNavProps) => {
  return (
    <div
      style={{
        paddingRight: "3.5rem",
        position: "sticky",
        top: "5.5rem",
        maxHeight: "calc(100vh - 5.5rem)",
        overflow: "auto",
      }}>
      {props.children}
    </div>
  );
};

type ComposeButtonProps = {
  href: string;
};

const ComposeButton = (props: ComposeButtonProps) => {
  const { hovered, handlers } = useHover();

  return (
    <Link
      {...handlers}
      to={props.href}
      style={{
        display: "grid",
        gridTemplateColumns: "auto 1fr",
        gridColumnGap: "1rem",
        alignItems: "center",
        padding: "1rem",
        boxShadow: shadow.INSET,
        borderRadius: "0.25rem",
        marginTop: "3rem",
        backgroundColor: hovered ? color.VERY_LIGHT_GRAY : "white",
      }}>
      <IconCircle icon="pencil" />
      <div style={{ color: color.DARK_GRAY }}>Write message</div>
    </Link>
  );
};

export const ConsultationTimelineView = () => {
  const store = useStore();
  const currentUser = useCurrentUser();
  const consultation = useConsultation();

  if (consultation.state === "DRAFT") {
    return null;
  }

  return (
    <div>
      <ConsultationHeader
        heading={consultation.name}
        backHref={path.consultationsRoot}
        statusIcon={
          consultation.state === "REQUESTED"
            ? "magnifier"
            : consultation.state === "MATCHED"
            ? "hourglass"
            : undefined
        }
        statusText={getStatusText({ consultation })}>
        <ConsultationHeaderLink
          label="Timeline"
          href={generatePath(path.consultationTimeline, {
            consultationId: consultation.id,
          })}
        />
        <ConsultationHeaderLink
          label="Details"
          href={generatePath(path.consultationInfo, {
            consultationId: consultation.id,
          })}
        />
      </ConsultationHeader>
      <div
        style={{
          maxWidth: size.MAX_WIDTH,
          margin: "0 auto",
          padding: `0 ${size.BODY_PADDING} 12rem`,
          display: "grid",
          gridTemplate: "auto / repeat(10, 1fr)",
          minHeight: "calc(100vh - 5.5rem - 8.25rem - 12rem)",
        }}>
        <Switch>
          <Route path={path.consultationTimeline}>
            <div style={{ gridColumn: "span 3" }}>
              <ConsultationTimelineNav>
                {currentUser.type === "CLIENT" && (
                  <ConsultationTimelineStage
                    label="Matching Stage"
                    href={generatePath(path.consultationTimelineMatchingStage, {
                      consultationId: consultation.id,
                    })}
                    status={
                      consultation.state === "REQUESTED" ? "XXX" : "COMPLETED"
                    }
                  />
                )}
                <ConsultationTimelineStage
                  label="Response Stage"
                  href={generatePath(path.consultationTimelineActiveStage, {
                    consultationId: consultation.id,
                  })}
                  status={
                    // prettier-ignore
                    consultation.state === "MATCHED" ? "XXX" :
                    consultation.state === "COMPLETED" ? "COMPLETED" :
                    "UPCOMING"
                  }
                  timeline={consultation.timeline.map(item => {
                    if (item.type === "MESSAGE") {
                      return {
                        id: item.id,
                        label: item.subject,
                        portrait: store.getUser(item.senderId)?.portrait,
                        date: toNow(item.createdDate),
                      };
                    }

                    if (currentUser.type === "CLIENT") {
                      if (item.type === "MATCH") {
                        return {
                          id: item.id,
                          label: "Consultant matched",
                          portrait: store.getUser(item.consultantId)?.portrait,
                          date: toNow(item.createdDate),
                        };
                      }
                    }

                    if (currentUser.type === "CONSULTANT") {
                      if (item.type === "REQUESTED") {
                        return {
                          id: item.id,
                          label: "Background info",
                          icon: "rocket",
                          date: toNow(item.createdDate),
                        };
                      }
                    }

                    return null;
                  })}
                />

                <ConsultationTimelineStage
                  label="Rate & Review"
                  href={generatePath(path.consultationTimelineCompleteStage, {
                    consultationId: consultation.id,
                  })}
                  status={
                    consultation.state === "COMPLETED" ? "XXX" : "UPCOMING"
                  }
                />
              </ConsultationTimelineNav>
            </div>
            <div style={{ gridColumn: "span 7" }}>
              <Switch>
                <Route path={path.consultationTimelineMatchingStage}>
                  <TimelineView>
                    {consultation.timeline.filter(
                      item => item.type === "REQUESTED",
                    )}
                  </TimelineView>
                </Route>
                <Route path={path.consultationTimelineActiveStage}>
                  {consultation.state === "REQUESTED" ? (
                    <TimelineBanner
                      heading="Nothing here (yet!)"
                      text="Once we find the right consultant, your correspondence with them will be here..."
                      subtle
                    />
                  ) : (
                    <Fragment>
                      <ComposeButton
                        href={generatePath(path.messageComposer, {
                          consultationId: consultation.id,
                        })}
                      />

                      <TimelineView>
                        {consultation.timeline.filter(item =>
                          currentUser.type === "CLIENT"
                            ? item.type === "MESSAGE" || item.type === "MATCH"
                            : item.type === "MESSAGE" ||
                              item.type === "REQUESTED",
                        )}
                      </TimelineView>
                    </Fragment>
                  )}
                </Route>
                <Route path={path.consultationTimelineCompleteStage}>
                  {consultation.state !== "COMPLETED" ? (
                    <TimelineBanner
                      heading={"Nothing here (yet!)"}
                      text="You will be able to rate the consultant after the consultation ends..."
                      subtle
                    />
                  ) : (
                    <TimelineView>
                      {consultation.timeline.filter(
                        item => item.type === "RATE_CONSULTANT",
                      )}
                    </TimelineView>
                  )}
                </Route>
                <Redirect
                  from={path.consultationTimeline}
                  to={pick(consultation.state, {
                    REQUESTED: path.consultationTimelineMatchingStage,
                    MATCHED: path.consultationTimelineActiveStage,
                    COMPLETED: path.consultationTimelineCompleteStage,
                  })}
                />
              </Switch>
            </div>
          </Route>
          <Route path={path.consultationInfo}>
            <div style={{ gridColumn: "span 10" }}>
              <NotInPrototypeView />
            </div>
          </Route>
          <Redirect to={path.consultationTimeline} />
        </Switch>
      </div>
    </div>
  );
};
