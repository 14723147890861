import React, { Fragment, useEffect, useState } from "react";
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  generatePath,
  useParams,
} from "react-router-dom";
import { SmallHeading } from "./headings";
import { HtmlText } from "./html-text";
import { color } from "./stylesheet";
import { when, template } from "./utils";
import { useDimensions, useHover } from "./hooks";
import {
  ConsultationHeader,
  ConsultationHeaderLink,
} from "./consultation-header";
import { path } from "./path";
import { useStore } from "./store-manager";
import { Input } from "./input";
import { Container } from "./container";
import { ConsultationInput } from "./types";
import { Button } from "./buttons";
import { LinkButton } from "./buttons";
import text from "./consultation-draft-view.json";

type ReviewBoxProps = {
  children: {
    text: string;
    value: string;
    type: string;
  }[];
};

const ReviewBox = (props: ReviewBoxProps) => {
  const [containerRef, containerDims] = useDimensions<HTMLDivElement>();
  const [innerRef, innerDims] = useDimensions<HTMLDivElement>();
  const [showButton, setShowButton] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const { hovered, handlers } = useHover();

  useEffect(() => {
    if (containerDims && innerDims) {
      if (containerDims.height < innerDims.height) {
        setShowButton(true);
      }
    }
  }, [containerDims, innerDims]);

  return (
    <div
      {...handlers}
      ref={containerRef}
      onClick={when(!expanded, () => setExpanded(true))}
      style={{
        position: "relative",
        backgroundColor: color.VERY_LIGHT_GRAY,
        padding: "1.5rem",
        overflow: "hidden",
        marginBottom: "4.25rem",
        maxHeight: when(!expanded, "18rem"),
        cursor: when(!expanded, "pointer"),
      }}>
      <div ref={innerRef}>
        {showButton && !expanded && (
          <div
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "8rem",
              backgroundImage: `linear-gradient(rgba(247,247,247,0), #F7F7F7)`,
            }}>
            <div
              style={{
                backgroundColor: "white",
                border: `1px solid ${color.LIGHT_GRAY}`,
                fontSize: "1.125rem",
                lineHeight: "1.5rem",
                padding: "0.5rem",
                minWidth: "6rem",
                textAlign: "center",
                color: color.DARK_GRAY,
                fontWeight: 700,
                transform: when(hovered, "scale(1.1)"),
              }}>
              Expand
            </div>
          </div>
        )}
        {props.children.map((field, i, arr) => {
          const isLast = i + 1 === arr.length;
          const isEmpty = field.value === "";
          const emptyMessage =
            field.type === "files"
              ? "<p>(No files attached)</p>"
              : "<p>(Missing response)</p>";

          return (
            <div
              key={field.text}
              style={{ marginBottom: when(!isLast, "2.5rem") }}>
              <div
                style={{
                  color: color.DARK_GRAY,
                  fontSize: "1rem",
                  lineHeight: "1.25rem",
                  fontWeight: 700,
                  marginBottom: "1.125rem",
                }}>
                {field.text}
              </div>
              <HtmlText
                color={when(isEmpty, "rgba(0,0,0,0.2)")}
                fontSize="small"
                marginBottom={when(!isLast, "1.125rem")}>
                {isEmpty ? emptyMessage : field.value}
              </HtmlText>
            </div>
          );
        })}
      </div>
    </div>
  );
};

type InputsSectionProps = {
  heading?: string;
  fields: Parameters<typeof Input>[0][];
};

const InputsSection = (props: InputsSectionProps) => {
  return (
    <div style={{ marginBottom: "4.25rem" }}>
      {props.heading && (
        <SmallHeading marginBottom="3rem">{props.heading}</SmallHeading>
      )}
      <div
        style={{
          display: "grid",
          gridGap: "3rem",
        }}>
        {props.fields.map(field => {
          return <Input key={field.label} {...field} />;
        })}
      </div>
    </div>
  );
};

const CaretRight = () => {
  return (
    <svg
      width="6px"
      height="10px"
      viewBox="0 0 6 10"
      style={{ margin: "0 1rem" }}>
      <g transform="translate(0.000000, -1.000000)">
        <polyline
          fill="none"
          stroke="#616161"
          opacity="0.6"
          transform="translate(3.051238, 6.000000) rotate(-180.000000) translate(-3.051238, -6.000000) "
          points="5.10247634 1 1 6 5.10247634 11"
        />
      </g>
    </svg>
  );
};

type ConsultationDraftStep1ViewProps = {
  nextHref: string;
  topicFields: Parameters<typeof Input>[0][];
  companyFields: Parameters<typeof Input>[0][];
};

const ConsultationDraftStep1View = (props: ConsultationDraftStep1ViewProps) => {
  return (
    <Fragment>
      <HtmlText marginBottom="4.25rem">{text.background_info_text}</HtmlText>

      <InputsSection
        heading={text.background_info_topic_inputs_heading}
        fields={props.topicFields}
      />

      <InputsSection
        heading={text.background_info_company_inputs_heading}
        fields={props.companyFields}
      />

      <LinkButton to={props.nextHref}>Next</LinkButton>
    </Fragment>
  );
};

type ConsultationDraftStep2ViewProps = {
  nextHref: string;
  additionaFields: Parameters<typeof Input>[0][];
};

const ConsultationDraftStep2View = (props: ConsultationDraftStep2ViewProps) => {
  return (
    <Fragment>
      <HtmlText marginBottom="4.25rem">{text.additional_info_text}</HtmlText>
      <InputsSection fields={props.additionaFields} />
      <LinkButton to={props.nextHref}>Next</LinkButton>
    </Fragment>
  );
};

type ConsultationDraftStep3ViewProps = {
  price: number;
  inputs: ConsultationInput[];
  onRequestClick: () => void;
};

const ConsultationDraftStep3View = (props: ConsultationDraftStep3ViewProps) => {
  return (
    <Fragment>
      <SmallHeading marginBottom="3rem">
        {text.review_and_pay_review_heading}
      </SmallHeading>
      <ReviewBox>{props.inputs}</ReviewBox>

      <SmallHeading marginBottom="3rem">
        {text.review_and_pay_payment_heading}
      </SmallHeading>

      <HtmlText marginBottom="2rem">
        {text.review_and_pay_payment_text}
      </HtmlText>

      <label
        style={{
          display: "grid",
          gridTemplate: "auto / 1rem 1fr",
          gridGap: "1rem",
          marginBottom: "2rem",
        }}>
        <input type="checkbox" />
        <HtmlText fontSize="small">
          {text.review_and_pay_payment_terms_and_conditions}
        </HtmlText>
      </label>

      <Button onClick={props.onRequestClick}>
        {template(text.review_and_pay_payment_call_to_action, {
          AMOUNT: props.price,
        })}
      </Button>
    </Fragment>
  );
};

export const ConsultationDraftView = () => {
  const store = useStore();
  const history = useHistory();
  const params = useParams<{ consultationId: string }>();
  const consultation = store.getDraftConsultationOrThrow(params.consultationId);

  const pathStep1 = generatePath(path.consultationDraftStep, {
    consultationId: consultation.id,
    step: "step-1",
  });
  const pathStep2 = generatePath(path.consultationDraftStep, {
    consultationId: consultation.id,
    step: "step-2",
  });
  const pathStep3 = generatePath(path.consultationDraftStep, {
    consultationId: consultation.id,
    step: "step-3",
  });

  return (
    <Fragment>
      <ConsultationHeader
        heading={consultation.name}
        backHref={path.consultationsRoot}>
        <ConsultationHeaderLink
          label={"1. " + text.background_info_label}
          href={pathStep1}
        />
        <CaretRight />
        <ConsultationHeaderLink
          label={"2. " + text.additional_info_label}
          href={pathStep2}
        />
        <CaretRight />
        <ConsultationHeaderLink
          label={"3. " + text.review_and_pay_label}
          href={pathStep3}
        />
      </ConsultationHeader>
      <Container marginTop="4.25rem">
        <Switch>
          <Route path={pathStep1} exact>
            <ConsultationDraftStep1View
              nextHref={pathStep2}
              topicFields={consultation.topicInputs.map(input => {
                return {
                  ...input,
                  label: input.text,
                  type: input.type,
                  onChange: event => {
                    store.updateDraftConsultationInputValue(
                      consultation.id,
                      "topicInputs",
                      input.text,
                      event.target.value,
                    );
                  },
                };
              })}
              companyFields={consultation.companyInputs.map(input => {
                return {
                  ...input,
                  label: input.text,
                  onChange: event => {
                    store.updateDraftConsultationInputValue(
                      consultation.id,
                      "companyInputs",
                      input.text,
                      event.target.value,
                    );
                  },
                };
              })}
            />
          </Route>
          <Route path={pathStep2} exact>
            <ConsultationDraftStep2View
              nextHref={pathStep3}
              additionaFields={consultation.additionalInputs.map(input => {
                return {
                  ...input,
                  label: input.text,
                  onChange: event => {
                    store.updateDraftConsultationInputValue(
                      consultation.id,
                      "additionalInputs",
                      input.text,
                      event.target.value,
                    );
                  },
                };
              })}
            />
          </Route>
          <Route path={pathStep3} exact>
            <ConsultationDraftStep3View
              price={consultation.price}
              onRequestClick={() => {
                store.promoteDraftConsultation(consultation.id);
                history.push(
                  generatePath(path.consultationRequested, {
                    consultationId: consultation.id,
                  }),
                );
              }}
              inputs={[
                ...consultation.topicInputs,
                ...consultation.companyInputs,
                ...consultation.additionalInputs,
              ]}
            />
          </Route>
          <Redirect from={path.consultationDraft} to={pathStep1} />
        </Switch>
      </Container>
    </Fragment>
  );
};
