import React from "react";
import { useHistory } from "react-router-dom";
import { LargeHeading, MediumHeading, Subheading } from "./headings";
import { HtmlText } from "./html-text";
import { Button } from "./buttons";
import { size, shadow } from "./stylesheet";
import { path } from "./path";
import { template } from "./utils";
import { useStore } from "./store-manager";
import text from "./login-view.json";

type UserBoxProps = {
  id: string;
  portrait: string;
  name: string;
  role: string;
};

const UserBox = (props: UserBoxProps) => {
  const history = useHistory();

  const BOX_PADDING = "2.25rem";
  const PORTRAIT_SIZE = "5.75rem";

  return (
    <div style={{ boxShadow: shadow.INSET, padding: "2.25rem" }}>
      <img
        src={props.portrait}
        alt=""
        style={{
          width: PORTRAIT_SIZE,
          height: PORTRAIT_SIZE,
          marginTop: `calc((${BOX_PADDING} + 1.125rem) * -1)`,
          borderRadius: "50%",
        }}
      />
      <MediumHeading>{props.name}</MediumHeading>
      <Subheading marginBottom="3rem">{props.role}</Subheading>
      <Button
        display="block"
        onClick={() => {
          localStorage.setItem("userId", props.id);
          history.push(path.root);
        }}>
        {template(text.log_in, { USER_NAME: props.name })}
      </Button>
    </div>
  );
};

export const LoginView = () => {
  const store = useStore();
  const { client, consultant } = store.getLoginUsers();

  return (
    <div
      style={{
        maxWidth: size.MAX_WIDTH,
        margin: "0 auto",
        padding: `3.5rem ${size.BODY_PADDING} 4rem`,
        minHeight: "calc(100vh - 7.5rem)",
        textAlign: "center",
      }}>
      <img src="/images/logo-clients.svg" alt="" />
      <div style={{ margin: "3.5rem 0 6rem" }}>
        <LargeHeading>{text.heading}</LargeHeading>
        <HtmlText>{text.text}</HtmlText>
      </div>

      <div
        style={{
          maxWidth: "45rem",
          margin: "0 auto",
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gridColumnGap: "2rem",
        }}>
        <UserBox
          id={client.id}
          name={client.name}
          portrait={client.portrait}
          role="Client"
        />
        <UserBox
          id={consultant.id}
          name={consultant.name}
          portrait={consultant.portrait}
          role="Consultant"
        />
      </div>
    </div>
  );
};
