import React, { Fragment, ReactNode } from "react";
import { Container } from "./container";
import { SecondaryNav, SecondaryNavLink } from "./secondary-nav";
import { Sidecar } from "./sidecar";
import { IconList } from "./icon-list";
import { color, size } from "./stylesheet";
import {
  LargeHeading,
  MediumHeading,
  SmallHeading,
  Subheading,
} from "./headings";
import { Portrait } from "./portrait";
import { useStore } from "./store-manager";
import { TimelineSection } from "./timeline-section";
import { Separator } from "./separator";
import { HtmlText } from "./html-text";
import { TextList } from "./text-list";
import { InfoBox } from "./info-box";
import { DialogContent, DialogOverlay } from "./dialog";
import { template, when } from "./utils";
import { Button } from "./buttons";
import { useCurrentUser } from "./user-view";
import { useParams, useHistory, generatePath } from "react-router-dom";
import { path } from "./path";
import text from "./opportunity-detail-view.json";

type OpportunityCoverProps = {
  userId: string;
  heading: ReactNode;
  tags: string[];
};

const OpportunityCover = (props: OpportunityCoverProps) => {
  return (
    <div
      style={{
        display: "flex",
        maxWidth: size.MAX_WIDTH,
        margin: "0 auto",
        padding: `4.5rem ${size.BODY_PADDING}`,
      }}>
      <Portrait userId={props.userId} size="4.75rem" marginRight="1.5rem" />
      <div>
        <LargeHeading>{props.heading}</LargeHeading>
        <Subheading>{props.tags.join(" • ")}</Subheading>
      </div>
    </div>
  );
};

type ClientProfileSectionProps = {
  highlights: { icon: string; text: string }[];
  bio: string;
  name: string;
};

const ClientProfileSection = (props: ClientProfileSectionProps) => {
  return (
    <Fragment>
      <MediumHeading id="client" marginBottom={size.MEDIUM_HEADING_SPACING}>
        {template(text.client_heading, { CLIENT_NAME: props.name })}
      </MediumHeading>
      <div
        style={{
          paddingBottom: "2rem",
          margin: "2rem 0 3rem",
        }}>
        <div
          style={{
            display: "grid",
            gridTemplate: "auto / repeat(6, 1fr)",
          }}>
          <div
            style={{
              gridColumn: "span 2",
            }}>
            {props.highlights.map((highlight, i, arr) => {
              const isLast = i + 1 === arr.length;

              return (
                <div
                  key={highlight.icon}
                  style={{
                    display: "flex",
                    marginBottom: when(!isLast, "1rem"),
                  }}>
                  <img
                    src={`/icons/${highlight.icon}.svg`}
                    alt=""
                    style={{ marginRight: "1rem", flex: "0 0 auto" }}
                  />
                  <div style={{ color: color.DARK_GRAY }}>{highlight.text}</div>
                </div>
              );
            })}
          </div>
          <div
            style={{
              gridColumn: "span 4",
              paddingLeft: "3.5rem",
              color: color.DARK_GRAY,
            }}
            dangerouslySetInnerHTML={{ __html: props.bio }}
          />
        </div>
      </div>
    </Fragment>
  );
};

type OutputsSectionProps = {
  list: string[];
};

const OutputsSection = (props: OutputsSectionProps) => {
  return (
    <Fragment>
      <MediumHeading id="outputs" marginBottom={size.MEDIUM_HEADING_SPACING}>
        {text.outputs_heading}
      </MediumHeading>
      <HtmlText>{text.outputs_text}</HtmlText>
      <TextList>{props.list}</TextList>
    </Fragment>
  );
};

type InputSectionProps = {
  topicList: string[];
  companyList: string[];
};

const InputsSection = (props: InputSectionProps) => {
  return (
    <Fragment>
      <MediumHeading id="inputs" marginBottom={size.MEDIUM_HEADING_SPACING}>
        {text.inputs_heading}
      </MediumHeading>
      <HtmlText>{text.inputs_text}</HtmlText>
      <SmallHeading marginTop="1.5rem">
        {text.topic_inputs_heading}
      </SmallHeading>
      <TextList>{props.topicList}</TextList>
      <SmallHeading marginTop="1.5rem">
        {text.company_inputs_heading}
      </SmallHeading>
      <TextList>{props.companyList}</TextList>
      <InfoBox
        icon={text.inputs_completion_icon}
        text={text.inputs_completion_text}
      />
    </Fragment>
  );
};

type PurchaseBoxProps = {
  consultantFee: number;
  onAcceptClick: () => void;
};

const PurchaseBox = (props: PurchaseBoxProps) => {
  const [showDialog, setShowDialog] = React.useState(false);
  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);

  return (
    <Fragment>
      <Sidecar>
        <div style={{ color: "white" }}>
          <span style={{ fontSize: "2rem", fontWeight: 900 }}>
            ${props.consultantFee}
          </span>{" "}
          <span style={{ opacity: 0.5 }}>fee for completion</span>
        </div>
        <IconList marginBottom="2rem">
          {[
            {
              icon: "calendar-full-white",
              text:
                "<p>3 days for initial consultation response, 3 days for followup questions from client.</p>",
            },
          ]}
        </IconList>
        <div
          onClick={open}
          style={{
            display: "block",
            color: "white",
            backgroundColor: color.RED,
            padding: "1.25rem",
            textAlign: "center",
            fontSize: "1.125rem",
            fontWeight: 700,
            cursor: "pointer",
          }}>
          Accept consultation
        </div>
      </Sidecar>
      <DialogOverlay
        style={{ zIndex: 100 }}
        isOpen={showDialog}
        onDismiss={close}>
        <DialogContent
          style={{
            boxShadow: "0px 10px 50px hsla(0, 0%, 0%, 0.33)",
            maxWidth: "33vw",
          }}>
          <MediumHeading marginBottom="1.5rem">
            Accept Consultation
          </MediumHeading>
          <HtmlText marginBottom="2rem">
            {`<p>You will be expected to respond within 3 working days and be
            available for followup questions for another 3 working days after
            that.</p>
            <p>Your consulting fee of $${props.consultantFee} will be processed after the
            consultation is completed.</p>`}
          </HtmlText>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button theme="white" onClick={close}>
              Cancel
            </Button>
            <Button onClick={props.onAcceptClick}>Accept</Button>
          </div>
        </DialogContent>
      </DialogOverlay>
    </Fragment>
  );
};

export const OpportunityDetailView = () => {
  const params = useParams<{ opId: string }>();
  const store = useStore();
  const opportunity = store.getConsultationOrThrow(params.opId);
  const client = store.getClientOrThrow(opportunity.clientId);

  const history = useHistory();
  const user = useCurrentUser();

  const sidebar = (
    <PurchaseBox
      consultantFee={opportunity.consultantFee}
      onAcceptClick={() => {
        store.acceptConsultation(user.id, opportunity.id);
        history.push(
          generatePath(path.consultationAccepted, {
            consultationId: opportunity.id,
          }),
        );
      }}
    />
  );

  return (
    <Fragment>
      <OpportunityCover
        userId={opportunity.clientId}
        heading={
          <Fragment>
            {store.getUser(opportunity.clientId)?.name}
            <span
              style={{
                color: color.DARK_GRAY,
                opacity: 0.5,
                fontWeight: 400,
                marginLeft: "1rem",
              }}>
              {opportunity.name}
            </span>
          </Fragment>
        }
        tags={opportunity.tags}
      />
      <Container sidebar={sidebar}>
        <SecondaryNav>
          <SecondaryNavLink label={text.client} href="client" />
          <SecondaryNavLink label={text.outputs} href="outputs" />
          <SecondaryNavLink label={text.inputs} href="inputs" />
          <SecondaryNavLink label={text.timeline} href="timeline" />
        </SecondaryNav>

        <ClientProfileSection
          highlights={client.highlights}
          bio={client.bio}
          name={client.name}
        />
        <Separator />

        <OutputsSection list={opportunity.outputs.map(o => o.text)} />
        <Separator />

        <InputsSection
          topicList={opportunity.topicInputs.map(input => input.text)}
          companyList={opportunity.companyInputs.map(input => input.text)}
        />
        <Separator />

        <TimelineSection
          heading={text.timeline_heading}
          text={text.timeline_text}
          stages={[
            {
              color: "#F7F7F7",
              text: template(text.timeline_response_stage, {
                CLIENT_NAME: client.name,
              }),
              days: 3,
            },
            {
              color: "#C7C7C7",
              text: template(text.timeline_followups_stage, {
                CLIENT_NAME: client.name,
              }),
              days: 3,
            },
          ]}
        />
      </Container>
    </Fragment>
  );
};
