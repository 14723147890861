import React from "react";
import { InfoView } from "./info-views";
import { path } from "./path";

type Props = {};
type State = { error: Error | null };

export class ErrorBoundary extends React.Component<Props, State> {
  state: State = { error: null };

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  render() {
    const { error } = this.state;

    if (error) {
      if (error.name === "Not Found Error") {
        return (
          <InfoView
            icon="mustache"
            heading="Not found"
            text="<p>Sorry, couldn't find this entity. Entities in the prototype don't persist over page refreshes. Try going back to the home screen to start over.</p>"
            links={[{ label: "Go to home screen", href: path.root }]}
          />
        );
      }
      return (
        <InfoView
          icon="mustache"
          heading="Something went wrong"
          text="<p>Whoose, sorry. Something didn't go as planned here. Try to refresh the page. If that doesn't help, you can always start over from the home screen.</p>"
          links={[{ label: "Go to home screen", href: path.root }]}
        />
      );
    }

    return this.props.children;
  }
}
