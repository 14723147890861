import React from "react";
import { when } from "./utils";
import { color } from "./stylesheet";

type SeparatorProps = {
  noLine?: boolean;
};

export const Separator = (props: SeparatorProps) => {
  return (
    <div
      style={{
        borderBottom: when(!props.noLine, `1px solid ${color.LIGHT_GRAY}`),
        margin: !props.noLine ? "4.25rem 0" : "4.25rem 0 0",
      }}
    />
  );
};
