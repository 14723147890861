import React from "react";
import { useStore } from "./store-manager";

type PortraitProps = {
  size?: string;
  userId?: string;
  portrait?: string;
  marginLeft?: string;
  marginRight?: string;
};

export const Portrait = (props: PortraitProps) => {
  const store = useStore();
  const size = props.size || "2.5rem";
  const src = props.userId
    ? store.getUser(props.userId)?.portrait
    : props.portrait;

  return (
    <img
      src={src}
      alt=""
      style={{
        display: "block",
        width: size,
        height: size,
        borderRadius: "50%",
        marginRight: props.marginRight,
        marginLeft: props.marginLeft,
      }}
    />
  );
};
