import React from "react";
import { color } from "./stylesheet";
import { pick } from "./utils";

type MoreButtonProps = {
  size?: "regular" | "large";
};

export const MoreButton = (props: MoreButtonProps) => {
  const size = pick(props.size || "regular", {
    regular: "2.5rem",
    large: "3rem",
  });

  return (
    <div
      onClick={event => {
        event.stopPropagation();
        event.preventDefault();
      }}
      style={{
        width: size,
        height: size,
        flex: "0 0 auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "not-allowed",
        boxShadow: `0 0 0 1px ${color.LIGHT_GRAY}`,
      }}>
      <img src="/icons/more.svg" alt="" />
    </div>
  );
};
