import React, { ReactNode } from "react";
import { Link as ScrollLink } from "react-scroll";
import { useHover } from "./hooks";
import { color } from "./stylesheet";

type SecondaryNavLinkProps = {
  label: string;
  href: string;
};

export const SecondaryNavLink = (props: SecondaryNavLinkProps) => {
  const { hovered, handlers } = useHover();
  return (
    <ScrollLink
      {...handlers}
      key={props.href}
      to={props.href}
      offset={-192}
      smooth
      style={{
        fontSize: "1.125rem",
        lineHeight: "1.5rem",
        padding: "1.5rem 1.5rem 1.5rem 0",
        color: hovered ? color.RED : color.DARK_GRAY,
        cursor: "pointer",
      }}>
      {props.label}
    </ScrollLink>
  );
};

type SecondaryNavProps = {
  children: ReactNode;
};

export const SecondaryNav = (props: SecondaryNavProps) => {
  return (
    <div
      style={{
        position: "sticky",
        display: "flex",
        top: "5.5rem",
        backgroundColor: "white",
        boxShadow: `0 -1px 0 0 ${color.LIGHT_GRAY}`,
        marginBottom: "2.75rem",
      }}>
      {props.children}
    </div>
  );
};
