import React from "react";
import { Link } from "react-router-dom";
import { LargeHeading } from "./headings";
import { HtmlText } from "./html-text";
import { size, color } from "./stylesheet";

type InfoView = {
  heading: string;
  text: string;
  icon?: string;
  links?: { href: string; label: string }[];
};

export const InfoView = (props: InfoView) => {
  return (
    <div>
      <div
        style={{
          display: "grid",
          gridTemplate: "auto / repeat(10, 1fr)",
          maxWidth: size.MAX_WIDTH,
          margin: "0 auto",
        }}>
        <div
          style={{
            gridColumn: "3 / span 6",
            padding: "6rem 0 16rem",
            textAlign: "center",
          }}>
          {props.icon && (
            <div
              style={{
                width: "8rem",
                height: "8rem",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%",
                border: `1px solid ${color.LIGHT_GRAY}`,
                marginBottom: "2rem",
              }}>
              <img
                src={`/icons/${props.icon}.svg`}
                alt=""
                style={{ width: "2.5rem", height: "2.5rem" }}
              />
            </div>
          )}
          <LargeHeading>{props.heading}</LargeHeading>
          <HtmlText marginBottom="2rem" marginTop="2rem">
            {props.text}
          </HtmlText>
          {props.links &&
            props.links.map(link => {
              return (
                <Link
                  to={link.href}
                  style={{
                    margin: "0 1rem",
                    fontSize: "1.125rem",
                    fontWeight: 700,
                    color: color.DARK_GRAY,
                  }}>
                  {link.label}
                </Link>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export const NotInPrototypeView = () => {
  return (
    <InfoView
      icon="hand"
      heading="Not part of the prototype"
      text="<p>This screen is not current part of the prototype. Try returning to where you came from to get back to the prototype flow.</p>"
    />
  );
};
